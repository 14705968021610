/* eslint-disable import/no-anonymous-default-export */
import {
    CREAR_FOLDER,
    OBTENER_FOLDERS
} from '../../types'

export default (state, action) => {
    switch(action.type){
        case CREAR_FOLDER:
            return {
                ...state,
                folder: action.payload
            };
        case OBTENER_FOLDERS:
            return {
                ...state,
                folders: action.payload
            };
        default:
            return state;
    }
}