import { Box, Typography } from "@mui/material";
import Menu from "../material/Menu";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import ModuleContext from "../../context/modules/moduleContext";

const Terminos = () => {
    const moduleContext = useContext(ModuleContext);
    const { seleccionarModulo, moduloSeleccionado } = moduleContext;
    const {id} = useParams();

    useEffect(()=>{
        if(!moduloSeleccionado){
            if(id) seleccionarModulo(id);
        }
    },[moduloSeleccionado])

    const styleFont = {
        fontFamily: 'Monserrat ProServicios',
        fontSize: '13px'
    }

    return(<Menu>
        <Box sx={{background: 'white', padding: '30px', borderRadius: '15px', boxShadow: '0px 0px 10px -2px gray'}}>
            <Typography textAlign="center" fontFamily={"Monserrat ProServicios"} variant="h4">Términos y Condiciones de Uso</Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Información relevante:</Typography>
            <Typography  sx={styleFont}>
                Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los 
                siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la 
                compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el 
                presente documento. 
            </Typography>
            <Typography  sx={styleFont}>
                Todas los productos  que son ofrecidos por nuestro sitio web pudieran ser creadas, cobradas, 
                enviadas o presentadas por una página web tercera y en tal caso estarían sujetas a sus propios Términos y Condiciones.
            </Typography>
            <Typography  sx={styleFont}>
                En algunos casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos 
                personales fidedignos y definición de una contraseña.
            </Typography>
            <Typography  sx={styleFont}>
                El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en
                caso de que se haya registrado y que sea necesario para la compra de alguno de nuestros productos.  no asume la
                responsabilidad en caso de que entregue dicha clave a terceros.
            </Typography>
            <Typography  sx={styleFont}>
                Todas las compras y transacciones que se lleven a cabo por medio de este sitio web, están sujetas a un proceso de
                confirmación y verificación, el cual podría incluir la verificación del stock y disponibilidad de producto,
                validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones
                requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de
                correo electrónico. 
            </Typography>
            <Typography  sx={styleFont}>
                Los precios de los productos ofrecidos en esta Tienda Online es válido solamente en las compras realizadas en este
                sitio web.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>
                Licencia
            </Typography>
            <Typography  sx={styleFont}>
                a través de su sitio web concede una licencia para que los usuarios utilicen  los productos que son vendidos en
                este sitio web de acuerdo a los Términos y Condiciones que se describen en este documento.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>
                Uso no autorizado
            </Typography>
            <Typography sx={styleFont}>
                En caso de que aplique (para venta de software, templetes, u otro producto de diseño y programación) usted no puede
                colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerlos
                para la redistribución o la reventa de ningún tipo.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>
                Propiedad
            </Typography>
            <Typography sx={styleFont}>
                Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar.
                Todos los productos son propiedad  de los proveedores del contenido. En caso de que no se especifique lo contrario,
                nuestros productos se proporcionan  sin ningún tipo de garantía, expresa o implícita. En ningún esta compañía será
                responsables de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o
                consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>
                Política de reembolso y garantía
            </Typography>
            <Typography sx={styleFont}>
               En el caso de productos que sean  mercancías irrevocables no-tangibles, no realizamos reembolsos después de que se envíe
               el producto, usted tiene la responsabilidad de entender antes de comprarlo.  Le pedimos que lea cuidadosamente antes de
               comprarlo. Hacemos solamente excepciones con esta regla cuando la descripción no se ajusta al producto. Hay algunos productos
               que pudieran tener garantía y posibilidad de reembolso pero este será especificado al comprar el producto. En tales casos la
               garantía solo cubrirá fallas de fábrica y sólo se hará efectiva cuando el producto se haya usado correctamente. La garantía
               no cubre averías o daños ocasionados por uso indebido. Los términos de la garantía están asociados a fallas de fabricación y
               funcionamiento en condiciones normales de los productos y sólo se harán efectivos estos términos si el equipo ha sido usado
               correctamente.
            </Typography>
            <Typography sx={styleFont}>Esto incluye:</Typography>
            <Typography sx={styleFont}>
                – De acuerdo a las especificaciones técnicas indicadas para cada producto.
            </Typography>
            <Typography sx={styleFont}>
                – En condiciones ambientales acorde con las especificaciones indicadas por el fabricante.
            </Typography>
            <Typography sx={styleFont}>
                – En uso específico para la función con que fue diseñado de fábrica.
            </Typography>
            <Typography sx={styleFont}>
                – En condiciones de operación eléctricas acorde con las especificaciones y tolerancias indicadas.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>
                Comprobación antifraude
            </Typography>
            <Typography sx={styleFont}>
                La compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por
                más tiempo para una investigación más rigurosa, para evitar transacciones fraudulentas.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>
                Privacidad
            </Typography>
            <Typography sx={styleFont}>
                Este  garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados
                por usuario o en el caso de requerir una validación de los pedidos no serán entregados a terceros, salvo que deba
                ser revelada en cumplimiento a una orden judicial o requerimientos legales.
            </Typography>
            <Typography sx={styleFont}>
                La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser seleccionada al momento
                de crear su cuenta. 
            </Typography>
            <Typography sx={styleFont}>
                reserva los derechos de cambiar o de modificar estos términos sin previo aviso.
            </Typography>
            <Typography sx={styleFont}>
            Estas terminos y condiciones se han generado en kookworks.com
            </Typography>

 
 
  
 
 
            <Typography fontSize={'12px'} sx={{textAlign: 'end', marginTop: '40px', fontFamily: 'Monserrat ProServicios'}}>
                En Proservicios 25 de Mayo del 2023
            </Typography>
            <Typography fontSize={'12px'} sx={{textAlign: 'end', fontFamily: 'Monserrat ProServicios'}}>
                Última modificación: 25 de Mayo del 2023 
            </Typography>
        </Box>
    </Menu>);
}

export default Terminos;
