import React, {useReducer} from 'react'
import SectionReducer from './sectionReducer'
import SectionContext from './sectionContext'
import clientAxios  from '../../config/axios'

import {
    MOSTRAR_SECCIONES
} from '../../types'

const SectionState = props => {
    const initialState = {
        secciones: []
    }

    const [ state, dispatch ] = useReducer(SectionReducer, initialState)

    const obtenerSecciones = async () => {
        try{
            const result = await clientAxios.get('/api/section');
            dispatch({
                type: MOSTRAR_SECCIONES,
                payload: await result.data.data
            });
        } catch(err){ console.log(err) }
    }

    return(
        <SectionContext.Provider
            value={{
                secciones: state.secciones,
                obtenerSecciones
            }}
        >
            {props.children}
        </SectionContext.Provider>
    )
}

export default SectionState;