import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Grid, styled, TextField, MenuItem, OutlinedInput, InputAdornment, IconButton, InputLabel, Button } from '@mui/material';    
import Menu from '../material/Menu';
import { MediaCard } from '../material';
import AuthContext from '../../context/auth/authContext';
import userImg from '../../img/icons/icon_user_1.jfif'
import Chart from 'react-google-charts';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import { useParams } from 'react-router-dom';
import ModuleContext from "../../context/modules/moduleContext";
import TuneIcon from '@mui/icons-material/Tune';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import { FormControl } from 'react-bootstrap';
import { AccountBoxOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import Icons from '../material/Icons';

const Profile = () => {
  const moduleContext = useContext(ModuleContext);
  const { seleccionarModulo, moduloSeleccionado } = moduleContext;
  const { id } = useParams();

  useEffect(()=>{ seleccionarModulo(id) },[])
  useEffect(()=>{
    if(!moduloSeleccionado){
      if(id) seleccionarModulo(id);
    }
    console.log(moduloSeleccionado)
  },[moduloSeleccionado])

  
  let random = Math.floor(Math.random() * 25)
  const authContext = useContext(AuthContext);
  const { usuario, modificarUsuario } = authContext;
  
  const [form, setForm] = useState(usuario);
  const [mensaje, setMensaje] = useState(`Hola ${usuario?.firstName} ${usuario?.lastName}`)
  const [typeTheme, setTheme] = useState(usuario?.typeTheme);
  const [roles, setRoles] = useState([
    "Administrador",
    "Lider",
    "Ayudante de cocina",
    "Personal"
  ])
  
  useEffect(()=>{
    setForm({
      ...form,
      typeTheme: form?.typeTheme !== typeTheme ? typeTheme: form?.typeTheme
    })
  },[typeTheme])

  const options = {
    chart: {
      title: "Rendimiento del usuario",
      subtitle: "in millions of dollars (USD)",
    },
};

  const data = (num = 2)=> {
    num = num * random
    return([
        [
        "Day",
        "Inicios de sesión",
        "Creación de recetas",
        "Rendimiento",
        ],
        [num*1.0, 1.2*num, num*1.0, num],
        [num*1.1, 3.2*num, num*1.2, num],
        [num*1.2, 1.5*num, num*1.4, num],
        [num*1.3, 2.2*num, num*1.6, num],
        [num*1.4, 0.5*num, num*1.8, num],
        [num*1.5, 3.2*num, num*2.0, num],
        [num*1.6, 1.2*num, num*1.2, num],
        [num*1.7, 2.2*num, num*2.4, num],
        [num*1.8, 2.32*num, num*3.0, num],
        [num*1.9, 3.2*num, num*1.2, num],
        [num*2.0, 0.2*num, num*3.4, num],
        [num*2.1, 0.2*num, num*3.5, num],
        [num*2.2, 2.2*num, num*3.7, num],
        [num*2.3, 3.2*num, num*1.9, num],
    ])
}

  const languajes = [
    { value: 0, label: 'Español' },
    { value: 1, label: 'English' }
  ];

  const measurement = [
    {
      value: 0,
      label: 'Métrico (Grados, Celsius, gramos, Kg)',
    }
  ]

  const [showPassword, setShowPassword] = useState(false)

  return (<Menu>
    
    <Grid container spacing={2} sx={{height: '78vh'}}>
      <Grid item xs={5}>
        <Grid container className='scroll-user-modules' sx={{
          overflowY: 'scroll',
          background: 'white',
          borderRadius: '15px',
          boxShadow: '0px 5px 6px -5px gray',
          padding: '10px',
          height: '73vh',
        }}>
          <Box sx={{display: 'flex'}}>
            <AccountBoxOutlined sx={{marginRight: '10px'}}/>
            <Typography
              sx={{
                fontFamily: 'Monserrat ProServicios',
                fontWeight: 'bold',
                fontSize: '20px',
            }}>
              Datos Personales
            </Typography>
          </Box>
          <Grid item xs={12} sx={{padding: '10px'}}>
              <Box sx={{position: 'relative', display: 'grid', justifyContent: 'center'}}>
                <ImgProfile src={userImg}/>
                <Box sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '20%',
                  background: 'white',
                  cursor: 'pointer',
                  borderRadius: '100%',
                  boxShadow: '0px 5px 6px -5px gray',
                  width: '40px',
                  height: '40px',
                  display: 'grid',
                  alignContent: 'center',
                  justifyContent: 'center'
                }}>
                  <AddAPhotoOutlinedIcon/>
                </Box>
              </Box>
              <Grid item xs={12} sx={{marginTop: '30px'}}>
                <Grid container>
                  <Grid xs={12} sx={{marginTop: '10px'}}>
                    <TextField
                      onChange={e => setForm({...form, firstName: e.target.value})}
                      value={form?.firstName}
                      variant='filled' label='Nombre'
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12} sx={{marginTop: '10px'}}>
                    <TextField
                      value={form?.lastName}
                      variant='filled'
                      label='Apellido'
                      fullWidth
                      onChange={e => setForm({...form, lastName: e.target.value})}
                    />
                  </Grid>
                  <Grid xs={12} sx={{marginTop: '10px'}}>
                    <TextField
                      value={form?.phone}
                      type="tel"
                      onChange={e => setForm({...form, phone: e.target.value})}
                      variant='filled'
                      label='Telefóno'
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontFamily: 'Monserrat ProServicios',
                        marginTop: '15px'
                    }}>
                      <b>Correo:</b> {usuario?.email}
                    </Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontFamily: 'Monserrat ProServicios',
                        marginTop: '5px'
                    }}>
                      <b>Puesto:</b>
                    </Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontFamily: 'Monserrat ProServicios',
                        marginTop: '5px'
                    }}>
                      <b>Rol:</b> {roles[usuario?.rol]}</Typography>
                    <Typography sx={{
                        fontSize: '13px',
                        fontFamily: 'Monserrat ProServicios',
                        marginTop: '5px'
                    }}>
                      <b>Fecha de creación:</b> {usuario?.createAt}</Typography>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Box className='scroll-user-modules' sx={{
          padding: '15px',
          padding: '30px 40px',
          background: 'white',
          borderRadius: '15px',
          boxShadow: '0px 5px 6px -5px gray',
          height: '73vh',
          overflowY: 'scroll'
        }}>
          <Box sx={{display: 'flex'}}>
            <TuneIcon sx={{marginRight: '10px'}}/>
            <Typography
              sx={{
                fontFamily: 'Monserrat ProServicios',
                fontWeight: 'bold',
                fontSize: '20px',
            }}>
              Personalización
            </Typography>
          </Box>
          <Typography sx={{fontSize: '15px', fontWeight: 'bold', fontFamily: 'Monserrat ProServicios', marginTop: '15px'}}>
            Mensaje de bienvenida
          </Typography>
          <TextField
            variant='filled'
            value={mensaje}
            sx={{
              marginTop: '5px',
              '& input':{
                padding: '8px 15px !important',
              }
            }}
            fullWidth
          />
          <Typography 
            sx={{
              fontSize: '15px',
              fontWeight: 'bold',
              fontFamily: 'Monserrat ProServicios',
              marginBottom: '5px',
              marginTop: '15px'
          }}>
            Colores de la página
          </Typography>
          <Grid container>
            <DisplaySelect action={{typeTheme, setTheme}} id={0} color1='black' color2='gold' color3='white'/>
            <DisplaySelect action={{typeTheme, setTheme}} id={1} color1='gold' color2='black' color3='white'/>
            <DisplaySelect action={{typeTheme, setTheme}} id={2} color1='#202529' color2='black' color3='white'/>
            <DisplaySelect action={{typeTheme, setTheme}} id={3} color1='#202529' color2='black' color3='#4b5b63'/>
          </Grid>
          <Box sx={{display: 'flex', marginTop: '65px'}}>
            <AppRegistrationIcon sx={{marginRight: '10px'}}/>
            <Typography
              sx={{
                fontFamily: 'Monserrat ProServicios',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
            >
              Preferencias
            </Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography 
                sx={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  fontFamily: 'Monserrat ProServicios',
                  marginBottom: '5px',
                  marginTop: '5px'
              }}>
                Idioma
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                defaultValue={0}
                fullWidth
                variant='filled'
                sx={{ "& .MuiSelect-select":{
                  paddingTop: '10px !important'}
              }}>
                {languajes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography 
                sx={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  fontFamily: 'Monserrat ProServicios',
                  marginBottom: '5px',
                  marginTop: '5px'
              }}>
                Sistema de medición
              </Typography>
              <TextField
                id="measurement"
                select
                defaultValue={0}
                fullWidth
                sx={{ "& .MuiSelect-select":{
                    paddingTop: '10px !important'}
                }}
                variant='filled'
              >
                {measurement.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Box sx={{display: 'flex', marginTop: '70px'}}>
            <ShieldOutlinedIcon sx={{marginRight: '10px'}}/>
            <Typography
              sx={{
                fontFamily: 'Monserrat ProServicios',
                fontWeight: 'bold',
                fontSize: '20px',
              }}
            >
              Seguridad
            </Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                variant='standard'
                label='Nueva Contraseña'
                type={showPassword ? 'text': 'password'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=>setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=>setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant='standard'
                label='Repetir Contraseña'
                type={showPassword ? 'text': 'password'}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=>setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=>setShowPassword(!showPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
    <Box sx={{position: 'sticky', bottom: '0px', textAlign:'end'}}>
      <Button 
          onClick={async ()=>{modificarUsuario(form)}}
          sx={{
              background:'#445056',
              '&:hover':{ background:'#d8b915'}
          }}
          variant="contained"
          endIcon={<Icons name='Save'
      />}>Guardar</Button>
    </Box>
  </Menu>);
}

const ImgProfile = styled("img")({
  width: '100%',
  borderRadius: '50%',
  boxShadow: '0px 2px 10px -5px black',
  maxWidth: '200px'
})

export default Profile;

const DisplaySelect = ({action, id=0, color1 = "white", color2 = "white", color3 = "white"}) => {
  let { typeTheme, setTheme } = action;
  const selectDisplay = (lado = "Right", ) => {
    const colorSelect = "#51c0f3";
    if(typeTheme === id){
      return {
        [`border${lado}`]: `4px solid ${colorSelect}`,
        borderBottom: `4px solid ${colorSelect}`,
        borderTop: `4px solid ${colorSelect}`,
        boxShadow: `0px 0px 7px 0px ${colorSelect}`
      }
    }
    return {}
  }
  return(<Grid
  xs={3}
  onClick={()=>{ setTheme(id) }}
  sx={{
    cursor: 'pointer'
}}>
  <Grid container sx={{paddingRight: '15px'}}>
    <Grid xs={2} sx={{
      boxShadow: '0px 5px 8px -5px gray',
      background: color1,
      ...(id === typeTheme ? selectDisplay("Left"): {})
    }}/>
    <Grid xs={10} sx={{ boxShadow: '0px 5px 8px -5px gray', ...selectDisplay("Right")}}>
      <Box sx={{background: color2, height: '15px'}}/>
      <Box sx={{background: color3, height: '60px'}}/>
    </Grid>
  </Grid>
</Grid>)
}