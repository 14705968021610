import Carousel from 'react-bootstrap/Carousel';
import banner1 from '../../img/banner-dashboard-1.jpg';
import banner2 from '../../img/banner-dashboard-2.jpg';
import banner3 from '../../img/banner-dashboard-3.jpg';
import { Typography } from '@mui/material';

const ImageCustom = ({size = '50vh', url}) => {
    return(<img
        className="d-block w-100"
        src={url}
        alt="Second slide"
        style={{maxHeight: size, objectFit: 'cover'}}
    />)
}

const BasicCarousel = () => {
  return (<Carousel>
      <Carousel.Item>
        <ImageCustom url={banner1}/>
        <Carousel.Caption>
            <Typography variant='h4'>First slide label</Typography>
            <Typography>Nulla vitae elit libero, a pharetra augue mollis interdum.</Typography>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ImageCustom url={banner2}/>
        <Carousel.Caption>
          <Typography variant='h4'>Second slide label</Typography>
          <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <ImageCustom url={banner3}/>
        <Carousel.Caption>
          <Typography variant='h4'>Third slide label</Typography>
          <Typography>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </Typography>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>);
}

export default BasicCarousel;