import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/auth.css'
import { Container, Box, Grid, Button, TextField, CssBaseline, Typography } from '@mui/material';
import AuthContext from '../../context/auth/authContext';
import AlertsContext from '../../context/alerts/alertContext';
import {Advertence, Footer, Icons} from '../material'
import url from '../../img/banner-login-1.jpg'

const Login = () => {
    const [open, setOpen] = useState(false);
    const handleClose = (event, reason) => {
        if(reason === 'clickaway') { return }
        setOpen(false);
    };
    
    const authContext = useContext(AuthContext);
    const { mensaje, login, autenticado } = authContext;

    const alertContext = useContext(AlertsContext);
    const { mostrarAlerta } = alertContext;

    const navigate = useNavigate();

    const [user, setUser] = useState({
        email: "",
        password: "",
        showPassword: false
    })

    const {email, password} = user;

    const onChange = e => { setUser({ ...user, [e.target.name] : e.target.value })}

    useEffect(()=>{
        if(autenticado){ navigate('/inicio'); }
        if(mensaje){ mostrarAlerta(mensaje.msg, 'alerta-error')
            return;
        }
    }, [autenticado, mensaje, navigate.state])
    
    const onSubmit = async e => {
        e.preventDefault();
        await login({email, password});
    }

    return(<Box>
        <Advertence open={open} actions={{handleClose}}/>
        <CssBaseline />
        <Container onSubmit={onSubmit} component="form" className='auth-container-login'>
            <Box className='auth-section-container' sx={{backgroundImage: `url(${url})`}}>
                <Grid className='auth-content-center' container spacing={2}>
                    <Grid className='auth-txt-center' item sm={12} md={2.5} xl={1} xs={12} lg={3.5}>
                        <Icons name='LogoYellow' dimensions={{w:200, h:200}}/>
                    </Grid>
                    <Grid className='auth-inputs-container' item sm={12} md={7} xl={10} xs={12} lg={5}>
                        <Grid className='auth-inputs-sections'>
                        <Typography sx={{marginBottom: '10px'}} variant='h5'>Iniciar Sesión</Typography>
                            <TextField
                                className='auth-inputs'
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                variant="outlined"
                                value={email}
                                onChange={onChange}
                            />
                            <TextField
                                className='auth-inputs auth-margin-top-inputs'
                                id="password"
                                name="password"
                                label="Contraseña"
                                variant="outlined"
                                type='password'
                                value={password}
                                onChange={onChange}
                            />
                            <Button className='auth-inputs-button' type="submit" variant="contained">Acceder</Button>
                            <Box sx={{marginTop: '10px'}}>
                                <Typography sx={{
                                    textAlign: 'center',
                                    paddingTop: '8px',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    color: '#2196F3'
                                }}>¿Haz olvidado tu contraseña?</Typography>
                                <Box sx={{
                                    width: '100% !important',
                                    display: 'flex',
                                    justifyContent: 'end'
                                }}>
                                    <Typography onClick={()=>navigate('/new-account')} sx={{
                                        background: "#757575",
                                        textAlign: 'center',
                                        marginTop: '20px',
                                        width: '50% !important',
                                        borderRadius: '10px',
                                        fontSize: '12px',
                                        paddingY: '8px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        '&:hover': {
                                            background: "#e47703",
                                        }
                                    }}>Crear cuenta nueva</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={2.5} xl={1} xs={12} lg={3.5}></Grid>
                </Grid>
            </Box>
            <Box sx={{height: '10vh'}}>
                <Footer/>
            </Box>
        </Container>
    </Box>)
} 

export default Login;