import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

const PrivateRoute = ({ children }) => {
    const authContext = useContext(AuthContext);
    const { usuarioAutenticado, autenticado, loading } = authContext;

    useEffect(()=> {
        console.log("Loaded route Private success")
        usuarioAutenticado();
    },[]);

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    if (!autenticado && !loading) return <Navigate to="/" replace />;
    return children;
}

export default PrivateRoute;
