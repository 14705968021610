import { HTML5Backend } from 'react-dnd-html5-backend'
import React, { Fragment, memo, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDrop, useDrag, DndProvider } from 'react-dnd'
import { Box, Button, Grid, InputAdornment, MenuItem, TextField, Typography, styled } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/Clear';
import ImagesStep from './ImagesStep';
import RecipeContext from '../../../context/recipes/recipeContext';
import { Icons } from '../../material';
import url from '../../../img/icons/folder-multilevel.png'

const App = ({state = [], folders, actions, setAction}) => {
  const {select, setSelect} = actions;
  const recipeContext = useContext(RecipeContext);
  const { obtenerRecetas, recetas } = recipeContext;
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [umbral, setUmbral] = useState(0);

  useEffect(()=>{
    if(ref?.current?.clientHeight){ setHeight(ref?.current?.clientHeight) }
    if(ref?.current?.clientWidth){setWidth(ref?.current?.clientWidth)}
  })
  
  useLayoutEffect(() => {
    obtenerRecetas()
    const handleResize = () => {
      setHeight(ref?.current?.clientHeight);
      setWidth(ref?.current?.clientWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize) }
  }, []);
  
  let filters = false;
  const [dustbin, setDustbin] = useState(false);
  const [data, setData] = useState(false);
  const [recipes, setRecipes] = useState([
    {id: 1, name: 'Empty'},
    {id: 2, name: 'Empty'},
    {id: 3, name: 'Empty'},
    {id: 4, name: 'Empty'},
    {id: 5, name: 'Empty'},
    {id: 6, name: 'Empty'},
    {id: 7, name: 'Empty'},
  ])

  useEffect(()=>{
    if(data && dustbin){
      filters = recipes.filter(f => f.id !== dustbin)
      filters = [...filters, {
        id: dustbin,
        name: data.name,
        times: data.dateTime,
        unit: data.unit
      }]
      filters.sort((x, y) => x.id - y.id);
      setRecipes(filters)
    }
  },[data])

  useEffect(()=>{
    // console.log(folders)
  },[select, data])

  const ItemTypes = { BOX: 'box' }
  const Container = memo(function Container() {
    return (<Grid container spacing={4}>
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={10}>
            <TextField sx={{
              '& .MuiInputBase-root':{
                borderRadius:'10px 0px 0px 10px !important',
              }
            }} fullWidth name='name' label="Nombre de la carpeta"/>
          </Grid>
          <Grid item xs={2}>
            <Button sx={{
              padding: '0px !important',
              width: '80%',
              height: '9vh',
              borderRadius:'0px 10px 10px 0px !important',
              '& .MuiButton-endIcon': { margin: '0px !important' }
            }} variant="contained" endIcon={<Icons name='Save'/>}/>
          </Grid>
        </Grid>
        <Box sx={{marginTop: '30px'}}>
          <TextField
            fullWidth
            name='folder'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={url} height='35px' style={{padding: '4% 5%'}}/>
                </InputAdornment>
              ),
            }}
            onChange={e=>{
              let val = e.target.value;
              let folder = folders[val]
              setSelect({id: val, name: folder.name})
              setAction(folder.recipes)
              console.log(folder.recipes)
            }}
            value={select.id}
            select
            label="Elegir carpeta"
          >
            {folders.map((folder, i) => {
              if(!folder?.isDeleted){
                return(<MenuItem key={i} value={i}>{folder.name}</MenuItem>)
              }
            })}
          </TextField>
        </Box>
        <Box sx={{marginTop: '30px'}}>
          <Box sx={{ overflow: 'hidden', clear: 'both', height: '47vh' }}>
            <Grid container spacing={2}>
              {state.length > 0 ? state.map((recipe, i) => {
                let res = recetas.filter(receta => receta._id === recipe._id && !receta?.isDeleted);
                if(res.length > 0){
                  let times = res[0]?.timeMax ? res[0]?.timeMax: recipe.times.timeMax;
                  const dateTime = times ? new Date(times * 1000).toISOString().slice(11, 19): '00:00:00';
                  return(<Grid item xs={12} sm={6} md={4}>
                    <Box sx={{
                      border: '1px dashed gray',
                      backgroundColor: 'white',
                      marginBottom: '5px',
                      cursor: 'move',
                      float: 'left',
                      borderRadius: '2px',
                      padding: '5px'
                    }}>
                      <Capsule key={i}
                        consult={{
                          _id: recipe._id,
                          name: res[0]?.name ? res[0]?.name: recipe.name,
                          dateTime,
                          unit: res[0]?.timeMax
                        }} name={recipe.name}
                      />
                    </Box>
                  </Grid>)}
                }
              ): null}
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box ref={ref}>
          {recipes.map((recipe, i)=>{
            if(umbral < recipe?.unit){setUmbral(recipe?.unit)}
            return(<Dustbin id={i+1} recipe={recipe} w={width} h={height} porcentaje={recipe.unit/umbral}/>)})
          }
        </Box>
      </Grid>
    </Grid>)
  })
  
  const Dustbin = ({recipe, id, w = 0, h=0, porcentaje=1}) => { 
    const statusColor = (status = 0) => {
      let backgroundColor = 'linear-gradient(0deg, #812302, #fe703e, #d45123) !important';
      if(status < 0.6667){
        backgroundColor = 'linear-gradient(0deg, #415b27, #9bd65f, #83ba4c) !important';
      }
      if(status < 0.6667 && status > 0.33334){
        backgroundColor = 'linear-gradient(0deg, #c38b00, #ffdf91, #f6c448) !important';
      }
      
      return backgroundColor
    } 
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: ItemTypes.BOX,
      drop: () => ({ name: 'Dustbin' }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      })
    }));
    const isActive = canDrop && isOver;
    if(isActive){ setDustbin(id) }

    return (<Box sx={{display: 'grid'}} ref={drop} data-testid="dustbin">
      <Box sx={{
        marginBottom: '3px',
        background: `#171b1e !important`,
        display: 'flex',
        borderRadius: '6px'
      }}>
        <HtmlTooltip
          title={recipe.name !=="Empty" ? 
            (<Box>
              <Typography textAlign='end' color="white">{recipe?.times}</Typography>
              <p style={{color: 'white'}}>{recipe.name}</p>
            </Box>): ''
        }>
        <Box sx={{width: w > 535 ? '20%': '65px', minWidth: '65px', display: 'flex'}}>
            <ImagesStep number={id} disabled={recipe.name !== "Empty" ? (id) : null}/>
            <Box sx={{
              display: w > 535 ? 'grid': 'none',
              alignContent: 'end',
              marginBottom: '5px',
              textAlign: 'end',
              width: '100% !important',
              paddingRight: '10px',
              minWidth: '50px'
            }}>
              <Typography sx={{
                position: 'relative',
                color: 'white',
                fontSize: '15px'
              }}>{recipe.name !== "Empty" ? recipe?.times: ''}</Typography>
              <Typography sx={{
                position: 'relative',
                color: 'white',
                fontSize: '9px'
              }}>{recipe.name !== "Empty" ? recipe.name: ''}</Typography>
            </Box>
          </Box>
      </HtmlTooltip>
        {isActive ? (<Box sx={{
          background: 'linear-gradient(0deg, #343434, #343434)',
          height: '5vh',
          width: `${(w*0.915)-75}px`,
          borderRadius: '0px 6px 6px 0px',
          margin: '2.5vh 0vh'
        }}/>) : recipe.name !== "Empty" ? (<Box sx={{
          background: 'linear-gradient(360deg, #2c2d2e, transparent)',
          height: '5vh',
          width: `${w > 535 ? '80%': `${(w*0.915)-65}px`}`,
          borderRadius: '0px 6px 6px 0px',
          marginY: '2.5vh',
          marginRight: '20px' 
        }}>
            <Box sx={{
              display: 'flex',
              width: `${porcentaje*100}%`,
              height: '5vh',
              borderRadius: '0px 6px 6px 0px',
              justifyContent: 'end',
              alignItems: 'center',
              background: statusColor(porcentaje)
            }}>
              <DeleteForeverIcon
                onClick={()=>{
                  filters = recipes.filter(f => f.id !== id)
                  filters = [...filters, {id, name: "Empty"}]
                  filters.sort((x, y) => x.id - y.id);
                  setRecipes(filters)
                }}
                sx={{ cursor: 'pointer', fontSize: '3vh', marginRight: '5px'}}
              />
            </Box>
          </Box>) : null}
      </Box>
    </Box>)
  }
  
  const Capsule = ({ name, consult }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.BOX,
      item:{ name, data: consult },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult()
        if(item && dropResult){
          if(consult){
            let recipeFilter = recetas.filter(receta=> receta._id === consult._id)
            if(recipeFilter.length > 0){ setData(consult) }
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }))
    const opacity = isDragging ? 0.4 : 1;

    return(<Box
      ref={drag}
      style={{ opacity,
        inlineSize: '100%',
        overflowWrap: 'break-word'
      }}
      data-testid={`box`}>
        {name}
    </Box>)
  }

  const HtmlTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#2c2d2e',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (<Box>
    <DndProvider backend={HTML5Backend}>
      <Container />
    </DndProvider>
  </Box>)
}

export default App;
