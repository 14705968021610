import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom'
import {
  Drawer, CssBaseline, Box, List,
  Divider, ListItem, ListItemButton, 
  ListItemIcon, Typography, Accordion,
  AppBar as MuiAppBar, AccordionSummary,
  ListItemText, AccordionDetails
} from '@mui/material';
import { ToolbarMain, Icons } from './'
import SectionContext from '../../context/sections/sectionContext';
import ModuleContext from '../../context/modules/moduleContext';
import AuthContext from '../../context/auth/authContext';

const drawerWidth = 190;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Menu = ({route = '', children, sx }) => {
  
  const [expanded, setExpanded] = useState(false);
  
  useEffect(()=>{
    if(route){ setExpanded(route ? route : false)}
  },[])

  const handleChange = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
  let subModulos = [];
  let modulosFiltrados = [];
  const sectionContext = useContext(SectionContext);
  const { obtenerSecciones, secciones } = sectionContext;

  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const moduleContext = useContext(ModuleContext);
  const { obtenerModulos, modulos, seleccionarModulo, moduloSeleccionado } = moduleContext;

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [leftTheme, setLeftTheme] = useState(0);
  
  const handleDrawerAction = validate => {setOpen(validate);};
  
  useState(()=>{
    obtenerSecciones();
    obtenerModulos();
  },[])

  useEffect(()=>{
    if(usuario){
      setLeftTheme(usuario?.typeTheme);
      return;
    }
  },[usuario])

  useState(()=>{ modulosFiltrados = modulos },[modulos])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar open={open}>
        <ToolbarMain open={open} handleDrawerAction={handleDrawerAction}/>
      </AppBar>
      
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            color: 'white !important',
            boxSizing: 'border-box',
            background: `var(--left-${leftTheme || leftTheme===0 ? leftTheme + 1: 1}-color)`
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{display: 'flex', justifyContent: 'center'}}>
          <Box
            onClick={()=>navigate('/inicio')}
            sx={{
              cursor: 'pointer',
              width: '100%',
              textAlign: 'center',
              display: 'grid',
              margin: "15px",
              justifyContent: 'center'
          }}>
            <Icons sx={{color:'white !important'}} name='LogoYellowTrans' dimensions={{w: '100%', h: 50}}/>
          </Box>
        </DrawerHeader>
        <Divider />
        <Box className="scroll-menu-modules" sx={{overflowY: 'scroll', height: '82vh'}}>
          <List>
            
            <SeccionModulo titulo='Contenido'>
              <TextoModulo onClick={e=>{navigate(`/recetas`)}}>Recetas</TextoModulo>
              <TextoModulo onClick={e=>{navigate(`/content/videos/recomendados`)}}>Videos</TextoModulo>
              <TextoModulo onClick={e=>{navigate(`/content/recommendation/recomendados`)}}>Recomendaciones</TextoModulo>
            </SeccionModulo>
            <SeccionModulo titulo='Configuraciones'>
              <TextoModulo onClick={e=>{navigate(`/settings/profile/MiltonRamos`)}}>Mi Perfil</TextoModulo>
              <TextoModulo onClick={e=>{navigate(`/machines/recomendada`)}}>Equipos</TextoModulo>
            </SeccionModulo>

            <TextoModulo onClick={e=>{navigate(`/settings/terminos/condiciones`)}}>Terminos y condiciones</TextoModulo>
            <TextoModulo onClick={e=>{navigate(`/settings/ad/aviso`)}}>Aviso de privacidad</TextoModulo>
          </List>
        </Box>
      </Drawer>
      <Main sx={sx} open={open}>
        <DrawerHeader sx={{minHeight: "35px !important"}}/>
        {children}
      </Main>
    </Box>
  );
}

export default Menu;

const TextoModulo     = styled('li')({
  padding: "4px 0px",
  paddingLeft: "30px",
  fontSize: "12px",
  cursor: "pointer",
  "&:hover":{
    background: "#4b4b4ba6"
  }
})

const TituloModulo     = styled('p')({
  margin: "0px",
  paddingLeft: "15px",
  fontSize: "14px",
  borderBottom: "1px solid #515151"
})

const SeccionModulo = ({titulo="", children, Icon=false}) => {
  return(<Box sx={{marginTop: "10px"}}>
    <Box>
      {Icon ? (<Icon/>): null}
      <TituloModulo>{titulo}</TituloModulo>
    </Box>
    <Box>
      {children}
    </Box>
  </Box>)
}