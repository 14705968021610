import { Search } from "@mui/icons-material";
import { Box, styled } from "@mui/material";

const SearchInput = styled('input')({
    border: 'none !important',
    background: 'transparent',
    width: '80%',
    padding: '10px 15px',
    outline: 'none !important',
})

const SearchField = ({callback}) => {
    return(<Box sx={{background: '#f1f0f0', borderRadius: '30px', display: 'flex'}}>
        <Box sx={{width: '40px', display: 'grid', alignContent: 'center'}}>
            <Search sx={{marginLeft: '15px'}}/>
        </Box>
        <SearchInput onChange={callback}/>
    </Box>)
}

export default SearchField;