import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Tabs, Tab, Typography, Box} from '@mui/material';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs = ({tabs, hx='68vh', columns = {xs: 2, sm: 2,  md: 2, lg: 2, xl: 2}}) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => { setValue(newValue) };
    let {xs, sm, md, lg, xl} = columns;

    return (<Box>
      <Grid sx={{marginTop: '10px', height: hx}} container spacing={2}>
            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
              <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                {tabs ? tabs.map((tab, i) => 
                  <Tab className='materials-tab-btn'
                    key={i}
                    label={tab.name}
                    {...a11yProps(0)} />)
                :null}
              </Tabs>
            </Grid>
            <Grid item xs={12-xs} sm={12-sm} md={12-md} lg={12-lg} xl={12-xl} sx={{height: hx}} className="materials-tabs-container">
              {tabs ? tabs.map((tab, i)=>{
                  return(<TabPanel key={i} sx={{ width: '100%'}}
                      value={value} index={i}>{tab.render}
                  </TabPanel>)
              }): null}
            </Grid>
        </Grid>
    </Box>);
}

export default VerticalTabs;
