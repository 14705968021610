import { Box, Typography } from "@mui/material";
import Menu from "../material/Menu";
import ModuleContext from "../../context/modules/moduleContext";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

const Aviso = () => {
    const moduleContext = useContext(ModuleContext);
    const { seleccionarModulo, moduloSeleccionado } = moduleContext;
    const {id} = useParams();

    useEffect(()=>{
        if(!moduloSeleccionado){
            if(id) seleccionarModulo(id);
        }
    },[moduloSeleccionado]);

    const styleFont = {
        fontFamily: 'Monserrat ProServicios',
        fontSize: '13px'
    }

    return(<Menu>
        <Box sx={{background: 'white', padding: '30px', borderRadius: '15px', boxShadow: '0px 0px 10px -2px gray'}}>
            <Typography textAlign="center" fontFamily={"Monserrat ProServicios"} variant="h4">AVISO LEGAL</Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Datos identificativos:</Typography>
            <Typography  sx={styleFont}>
                En cumplimiento con el deber de información recogido en el artículo 10 de la Ley 34/2002, 
                de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, 
                a continuación se indican los datos de información general del sitio Web (dirección de la
                página web o blog) y se exponen los datos fiscales de la empresa, siendo responsable de la
                explotación la mercantil HOTEL (datos de la empresa, del autónomo, etc.).
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Objeto del Aviso Legal:</Typography>
            <Typography sx={styleFont}>
                El Aviso Legal regula las condiciones, el acceso y la utilización del sitio Web, sus contenidos
                y servicios, de pago o gratuitos, puestos a disposición de los Usuarios; y la política de reservas
                de HOTEL con sus Clientes.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Protección de datos:</Typography>
            <Typography sx={styleFont}>
                De conformidad con lo dispuesto en el Art. 5 y 6 de la Ley Orgánica 15/1999 de Protección de Datos de
                Carácter Personal, así como en los artículos 12, 14 y 18 del R.D. 1720/2007 de 21 de diciembre, le
                informamos que los datos suministrados pasarán a formar parte del fichero «PARTE ENTRADA VIAJEROS» de
                HOTEL, inscrito en la Agencia Española de Protección de Datos (AEPD) con el código (número de identificación
                Protección de Datos), autorizando expresamente que los mismos sean objeto de tratamiento para atender
                su solicitud, consulta y/o reserva. Así mismo, queda informado de la posibilidad de ejercer sus derechos
                de acceso, rectificación, cancelación y oposición (previstos en los artículos 15, 16 y 17 de la LOPD, así
                como en los artículos 23 a 36 ambos inclusive del R.D. 1720/2007 de 21 de diciembre) por medio de comunicación
                a la siguiente dirección de correo electrónico (e-mail). También mediante carta dirigida a la dirección
                arriba indicada.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Propiedad intelectual:</Typography>
            <Typography sx={styleFont}>
                La información y los contenidos que se recogen en el presente sitio Web, así como el código fuente, los
                diseños gráficos, las imágenes, las fotografías, el software y los textos, están protegidos por la legislación
                española sobre los derechos de propiedad intelectual e industrial a favor de HOTEL y no se permite la
                reproducción y/o publicación, total o parcial, del sitio Web, ni su tratamiento informático, su distribución,
                su difusión, su modificación, su transformación ni demás derechos reconocidos legalmente a su titular, sin
                el permiso previo y por escrito del mismo. Todos los derechos derivados de la propiedad intelectual están
                expresamente reservados por HOTEL. El Usuario, única y exclusivamente, puede utilizar el material que aparezca
                en este sitio web para su uso personal y privado, quedando prohibido su uso con fines comerciales o para
                incurrir en actividades ilícitas.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Exclusión de garantías y responsabilidad:</Typography>
            <Typography sx={styleFont}>
                HOTEL no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran
                ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o
                la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las
                medidas tecnológicas necesarias para evitarlo.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Modificaciones:</Typography>
            <Typography sx={styleFont}>
                HOTEL se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su sitio
                Web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma
                como la forma en la que éstos aparezcan presentados o localizados en su sitio Web.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Vínculos a Terceros:</Typography>
            <Typography sx={styleFont}>
                En el caso de vínculos a sitios webs de terceros, el Usuario pasará a estar regido por los Términos de Uso y
                Política de Privacidad del nuevo sitio. HOTEL no será responsable ni tendrá obligación legal por el uso de tales
                sitios.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Generalidades:</Typography>
            <Typography sx={styleFont}>
                HOTEL no realizará publicidad engañosa a través de su sitio Web. A estos efectos, por lo tanto, no serán considerados
                como publicidad engañosa los errores formales o numéricos que puedan encontrarse a lo largo del contenido de las
                distintas secciones del sitio web producidos como consecuencia de un mantenimiento y/o actualización incompleta
                o defectuosa de la información contenida es estas secciones. HOTEL, como consecuencia de lo dispuesto en este
                apartado, se compromete a corregirlo tan pronto como tenga conocimiento de dichos errores. HOTEL no se hace
                responsable del incumplimiento de cualquier norma aplicable en que pueda incurrir el Usuario en su acceso al
                sitio Web y/o en el uso de las informaciones contenidas en el mismo.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Servicios de Reserva on-line:</Typography>
            <Typography sx={styleFont}>
                El servicio de reserva on-line tiene por finalidad la posibilidad de reservar habitaciones en las fechas deseadas
                en HOTEL. La utilización de este servicio conlleva la aceptación de estas condiciones generales y la de todos los
                términos y condiciones recogidos por HOTEL.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Política de cancelaciones:</Typography>
            <Typography sx={styleFont}>
                Si el Cliente cancela o modifica la reserva hasta 7 días antes de la fecha de llegada, HOTEL no efectuará cargos.
                Si el Cliente cancela o modifica su reserva fuera de plazo o no se presenta, HOTEL retendrá el 100% del prepago de
                la reserva o cargará en la tarjeta de crédito del Cliente dicho importe, correspondiente al 40% del total, en
                concepto de indemnización.
            </Typography>
            <Typography sx={{marginTop: '20px'}} variant="h6" fontWeight="bold" fontFamily={"Monserrat ProServicios"}>Legislación aplicable y Jurisdicción:</Typography>
            <Typography sx={styleFont}>
                Las partes, con renuncia a su fuero propio, si ello fuera posible, se someten a los Juzgados y Tribunales de la
                provincia de Proservicios. La Ley aplicable será la española.
            </Typography>
            <Typography fontSize={'12px'} sx={{textAlign: 'end', marginTop: '40px', fontFamily: 'Monserrat ProServicios'}}>
                En Proservicios 25 de Mayo del 2023
            </Typography>
            <Typography fontSize={'12px'} sx={{textAlign: 'end', fontFamily: 'Monserrat ProServicios'}}>
                Última modificación: 25 de Mayo del 2023 
            </Typography>
        </Box>
    </Menu>);
}

export default Aviso;