import React, {Fragment, useState, useRef} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Box, Button, ClickAwayListener, MenuList,
    ButtonGroup, Paper, Popper, MenuItem, Grow
} from '@mui/material'; 

const SplitButton = ({options = [], sx, color, hover, textColor='#ffffff', fullWidth = false}) => {
    const styleHover = {"&:hover": hover};
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = () => {
      console.info(options[selectedIndex]);
      let fn = options[selectedIndex].action;
      fn(true)
    };
    const handleToggle = () => { setOpen((prevOpen) => !prevOpen) };

    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
    };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) { return }
    setOpen(false);
  };

  return(
    <Fragment>
      <Box sx={sx}>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" fullWidth={fullWidth}>
          <Button
            sx={[{
              textTransform: 'none',
              color: textColor,
              background: color,
              borderColor: `${color} !important`
            }, styleHover]}
            onClick={handleClick}
          >{options[selectedIndex].name}</Button>
          <Button
            sx={[{
                textTransform: 'none',
                background: color,
                borderColor: `${color} !important`,
                maxWidth: '20px'
            }, styleHover]}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon color='black'/>
          </Button>
        </ButtonGroup>
      </Box>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      color='black'
                      // disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >{option.name}</MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}


export default SplitButton;