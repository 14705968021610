
const dateFormat = (date) => {
    const event = new Date(date);
    const options = { 
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    // const month = [
    //     "January", "February", "March",
    //     "April", "May", "June",
    //     "July", "August", "September",
    //     "October", "November", "December"
    // ];
    // const d = new Date(date);
    // let m = month[d.getUTCMonth()];
    // let year = d.getUTCFullYear();
    return event.toLocaleDateString('es-MX', options)
}
export default dateFormat;