import { Box, Button, Grid, InputAdornment, TextField, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ModuleContext from "../../context/modules/moduleContext";
import { Search } from "@mui/icons-material";
import SplitButton from "../material/SplitButton";
import { Icons } from "../material";
import SearchField from "../material/SearchField";

const Videos = () => {
  const moduleContext = useContext(ModuleContext);
  const { seleccionarModulo, moduloSeleccionado } = moduleContext;
  const {id} = useParams();

  const options = [
    { icon: <Icons name='newRecipe'/>,
      name: 'Todos',
      action: ()=>console.log("Test") 
    },
    { icon: <Icons name='newRecipeML'/>,
      name: 'Por Fecha',
      action: ()=>console.log("Test") 
    },
    { icon: <Icons name='AddRecipeSimp'/>,
      name: 'Crear Receta Inteligente'
    }
  ];

  const [contenido, setContenido] = useState([
    {
        _id: "b00001",
        typeFormat: 0,
        title: "Receta de cocina al vacío",
        url: "https://www.youtube-nocookie.com/embed/tNap60mB_lg?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00002",
        typeFormat: 0,
        title: "Proceso de mantenimiento",
        url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00003",
        typeFormat: 0,
        title: "Receta costillas BBQ",
        url: "https://www.youtube-nocookie.com/embed/daw0Qh_Nf4s?controls=0",
        description: "Receta para elaborar tus costillas BBQ en el horno de KookWorks",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00004",
        typeFormat: 0,
        title: "Receta de cocina al vacío",
        url: "https://www.youtube-nocookie.com/embed/2e9C1iCmtVs?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00005",
        typeFormat: 0,
        title: "Proceso de mantenimiento",
        url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00006",
        typeFormat: 0,
        title: "Proceso de mantenimiento",
        url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00007",
        typeFormat: 0,
        title: "Receta de cocina al vacío",
        url: "https://www.youtube-nocookie.com/embed/tNap60mB_lg?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00008",
        typeFormat: 0,
        title: "Proceso de mantenimiento",
        url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    {
        _id: "b00009",
        typeFormat: 0,
        title: "Proceso de mantenimiento",
        url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    }
  ])

  const [filter, setFilter] = useState(contenido)

    const myFunction = (word) => {
        let words = word.target.value;
        let letters = words.toUpperCase();
        if(letters) {
            let palabrasEnFiltro = letters.split(' ');
            let hallado = 0;
            if(palabrasEnFiltro?.length > 0){
                palabrasEnFiltro?.map(w => {
                    setFilter(contenido.filter(wFilter => wFilter.title.toUpperCase().indexOf(w) !== -1))}
                )
            }
            // if (h1.innerHTML.toUpperCase().indexOf(filtro) > -1) {
                // hallado++;
            // }
        }else setFilter(contenido)
    }

        
    

  useEffect(()=>{ seleccionarModulo(id) },[])
  useEffect(()=>{
    if(!moduloSeleccionado){
        if(id) seleccionarModulo(id);
    }
  },[moduloSeleccionado])

  return(<Box>
    <Grid sx={{
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px -5px gray',
        padding: '20px',
        marginBottom: '25px'
    }} container>
        <Grid item xs={8}><SearchField callback={myFunction}/></Grid>
        <Grid item xs={4}>
            <SplitButton
                fullWidth = {true}
                sx={{
                    alignSelf: 'center',
                    textTransform: 'none',
                    marginLeft: '20px'
                }}
                color='var(--top-1-color)'
                hover={{ 
                    boxShadow: '0px 0px 10px -5px black !important',
                    background: 'var(--top-1-color) !important',
                    opacity: 0.5
                }}
                options={options}
            />
        </Grid>
    </Grid>
    <Grid container spacing={5}>
        {filter.map(v => {
            return(<Grid item xs={12} sm={12} md={4}>
                {v.typeFormat === 0 ? (<Box sx={{
                    background: 'white',
                    borderRadius: '10px',
                    paddingBottom:"3%",
                    boxShadow: '0px 0px 10px -5px gray',
                    "& .ytp-title": {display: 'none !important'}
                }}>
                    <iframe
                        width="94%"
                        height="190"
                        style={{borderRadius: '10px', margin: '3%'}}
                        src={v.url}
                        title={v.title}
                        frameborder="0"
                        allow=""
                        allowfullscreen
                    />
                    <Box>
                        <Typography sx={{
                            fontFamily: 'Monserrat ProServicios',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}>
                            {v.title}
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Monserrat ProServicios',
                            textAlign: 'center',
                            fontSize: '12px',
                            marginRight: '10px'
                        }}>
                            {v.description}
                        </Typography>
                    </Box>
                </Box>): (<Typography>No hay videos relacionados</Typography>)}
            </Grid>)
        })}
    </Grid>
  </Box>)
}

const SearchInput = styled('input')({
    border: 'none !important',
    background: 'transparent',
    width: '80%',
    padding: '10px 15px',
    outline: 'none !important',
})

export default Videos;