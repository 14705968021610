import { Box, Fab, Grid, TextField, Typography } from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '../material/Menu';
import Paretto from "../material/Paretto";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import { Icons } from "../material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ScaleLoader } from 'react-spinners'
import ModuleContext from "../../context/modules/moduleContext";
import { useParams } from "react-router-dom";

const titles = {
    textAlign: 'center', 
    fontWeight: 'bold'
}

const selectBlock = {
    borderRadius: '10px',
    paddingY: '8px',
    paddingLeft: '16px',
    margin: '10px 6px',
    cursor: 'pointer'
}

const Machines = () => {
    const moduleContext = useContext(ModuleContext);
    const { seleccionarModulo, moduloSeleccionado } = moduleContext;
    const {id} = useParams();

    useEffect(()=>{ seleccionarModulo(id) },[])
    useEffect(()=>{
        if(!moduloSeleccionado){
        if(id) seleccionarModulo(id);
        }
        console.log(moduloSeleccionado)
    },[moduloSeleccionado])

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [display, setDisplay] = useState(0)
    const [machines , setMachines] = useState([
        {name: "Horno Cocina central", status: 53, limpieza: 64},
        {name: "Horno área comedor", status: 45, limpieza: 22},
        {name: "Horno restaurante", status: 67, limpieza: 72},
    ])

    const saveMachines = params =>{
        let result = {
            name: params.name,
            status: Math.floor(Math.random() * 101),
            limpieza: Math.floor(Math.random() * 101)
        }

        setMachines([...machines, result]);
        setDisplay(100)
        setTimeout(()=>{ setDisplay(0) }, "2000");
    }

    return(<Menu>
            <Grid container sx={{height: '80vh'}} spacing={2}>
                <Grid item xs={4}>
                    <Box sx={{
                        boxShadow: '0px 3px 7px -4px gray',
                        borderRadius: '15px',
                        minHeight: '50vh',
                        height: '76vh',
                        background: 'white',
                        position: 'relative'
                    }}>
                    {display === 0 ? (<Box>
                        <SelectedListItem
                            machines={machines}
                            states={{value: selectedIndex, action: setSelectedIndex}}
                        />
                            <Fab
                                variant="extended"
                                size="medium"
                                sx={{
                                    position: 'absolute',
                                    bottom: '0px',
                                    marginBottom: '12px',
                                    right: '12px',
                                    color: 'white',
                                    textTransform: 'none',
                                    background:'#445056',
                                    '&:hover':{ background:'#d8b915'}
                                }}
                                aria-label="add"
                                onClick={()=>setDisplay(1)}
                            >
                                <AddIcon sx={{ mr: 1 }} />
                                Añadir máquina
                            </Fab>
                    </Box>): display === 1 ? (<NewMachine back={()=> setDisplay(0)} callback={saveMachines}/>): 
                    display === 100 ? (<Grid sx={{
                        height: '70vh', 
                        display: 'grid',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}>
                        <Box>
                            <ScaleLoader
                                cssOverride={{
                                    textAlign: 'center',
                                }}
                                speedMultiplier={0.6}
                                margin={5}
                                color="#3b464b"
                                width={12}
                                height={100}
                            />
                            <Typography>Vinculando equipo</Typography>
                        </Box>
                    </Grid>):null}
                    </Box>
                </Grid>
                <Grid className="tabScrollStyle" item xs={8}>
                    <Grid container sx={{
                        boxShadow: '0px 3px 7px -4px gray',
                        borderRadius: '15px',
                        minHeight: '50vh',
                        height: '76vh',
                        background: 'white',
                    }}>
                        <Grid item xs={6} sx={{marginTop: '20px'}}>
                            <Typography sx={titles}>Nivel de funcionamiento del horno</Typography>
                            <Box sx={{padding: '0px 20%', marginTop: '20px'}}>
                                <Paretto status={machines.length > 0 ? machines[selectedIndex]?.status: 0}/>
                            </Box>
                            <Box>
                                <Typography sx={{
                                    marginTop: '15px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}>Fecha de última actualización:</Typography>
                                <Typography sx={{
                                    fontSize: '12px',
                                    textAlign: 'center'
                                }}>11/05/2023 15:05:33</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ marginTop: '20px'}}>
                            <Typography sx={titles}>Limpieza del horno</Typography>
                            <Box>
                                <Typography sx={{
                                    marginTop: '15px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}>Fecha de última limpieza del horno:</Typography>
                                <Typography sx={{
                                    fontSize: '12px',
                                    textAlign: 'center'
                                }}>11/05/2023 15:05:33</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{
                                    marginTop: '25px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    textAlign: 'center'
                                }}>Nivel de limpieza:</Typography>
                                <Box sx={{padding: '0px 20%', marginTop: '10px'}}>
                                    <Paretto status={machines.length > 0 ? machines[selectedIndex]?.limpieza: 0} borderColor="#03a9f4"/>
                                </Box>
                                <FormControlLabel
                                    sx={{
                                        marginTop:'10px',
                                        paddingX: '20px',
                                        '& span':{fontSize: '12px !important'}
                                    }}
                                    control={<Checkbox defaultChecked />}
                                    label="Notificar limpieza del horno"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    </Menu>)
}

const SelectedListItem = ({machines = [], states={action: ()=>console.log("select"), value: 0}}) => {
    let {action, value} = states;
    const handleListItemClick = (event, index) => { action(index) };
  
    return (<Box sx={{ width: '100%'}}>
        <Typography
            variant="h6"
            sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                paddingTop: '15px'
            }}>
                Máquinas vinculadas
            </Typography>
        <List component="nav" aria-label="secondary mailbox folder" sx={{'& .Mui-selected':{background: 'gold !important'}}}>
            {machines.length > 0 ? machines.map((machine, i)=> (<ListItemButton
                selected={value === i}
                sx={{'&:hover':{background: '#fff7ce !important'}}}
                onClick={(event) => handleListItemClick(event, i)}>
            <ListItemText primary={machine.name} />
          </ListItemButton>)):null}
        </List>
    </Box>);
}

const NewMachine = ({back = ()=>console.log('back'), callback = ()=>console.log('callback')}) => {
    const [params, setParams] = useState({ id: '', name: '' })
    return(<Box>
        <Grid container sx={{marginTop: '20px', paddingX: '10px'}}>
            <Grid item xs={2} onClick={back} sx={{cursor: 'pointer'}}><KeyboardBackspaceIcon/></Grid>
            <Grid item xs={8}><Typography sx={titles}>Nueva vinculación</Typography></Grid>
            <Grid item xs={2}></Grid>
        </Grid>
        <Box sx={{ margin: '5% 8%' }}>
            <TextField
                sx={{width: '100%', paddingBottom: '25px'}}
                id="id"
                name="id"
                value={params.id}
                onChange={e=>setParams({...params, [e.target.name]: e.target.value})}
                label="Id del equipo"
                variant="outlined"
            />
            <TextField
                sx={{width: '100%', paddingBottom: '25px'}}
                id="name"
                name="name"
                value={params.name}
                onChange={e=>setParams({...params, [e.target.name]: e.target.value})}
                label="Nombre"
                variant="outlined"
            />
        </Box>
        <Fab
            variant="extended"
            size="medium"
            sx={{
                position: 'absolute',
                bottom: '0px',
                marginBottom: '12px',
                right: '12px',
                color: 'white',
                textTransform: 'none',
                background:'#445056',
                '&:hover':{ background:'#d8b915'}
            }}
            aria-label="add"
            onClick={()=>callback(params)}
        >Guardar<Box sx={{marginLeft: '6px'}}><Icons name='Save'/></Box></Fab>
    </Box>)
}

export default Machines;