import { Box, Button, Grid, styled, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import img from '../../img/banner-account.jpg'
import { Footer, Icons } from "../material";
import AlertsContext from "../../context/alerts/alertContext";
import url from '../../img/banner-dashboard-1.jpg'
const NewAccount = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const { registrarUsuario, mensaje } = authContext;

    const alertContext = useContext(AlertsContext);
    const { mostrarAlerta } = alertContext;

    const [data, setData] = useState(false);
    const [error, setError] = useState(false);
    const handleChange = e => { setData({ ...data, [e.target.name]: e.target.value})};
    const submitForm = async () => {
        if(data.firstName && data.lastName && data.email && data.password && data.repeatPassword){
            if(data.password === data.repeatPassword){
                let res = await registrarUsuario(data);
                if(res){ navigate("/") }
            }
        }
    }

    useEffect(()=>{
        if(mensaje){
            mostrarAlerta(mensaje.msg, 'alert-error')
        }
    },[mensaje])
    useEffect(()=>{
        if(data.password !== data.repeatPassword){ setError(true)}
        else{ setError(false) }
    },[data])

    const ImageBanner = styled('img')({
        width: '100%',
        height: '100vh'
    })

    return(<Box>
        <Box className='auth-section-container' sx={{backgroundImage: `url(${url})`}}>
                <Grid className='auth-content-center' container spacing={2}>
                    <Grid className='auth-txt-center' item sm={2.5} md={2.5} xl={1} xs={2} lg={3.5}>
                        <Box sx={{cursor:'pointer !important'}} onClick={()=>navigate('/')}>
                            <Icons cursor='pointer !important' name='LogoYellow' dimensions={{w:120, h:120}}/>
                        </Box>
                    </Grid>
                    <Grid className='auth-inputs-container' item sm={7} md={7} xl={10} xs={8} lg={5}>
                        <Grid className='auth-inputs-sections'>
                            <Box sx={{margin: '0px'}}>
                                <Typography variant="h4">Crear cuenta nueva</Typography>
                                <TextField
                                    className="auth-inputs auth-margin-top-inputs"
                                    id="firstName"
                                    name="firstName"
                                    label="Nombres"
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                                <TextField
                                    className='auth-inputs auth-margin-top-inputs'
                                    id="lastName"
                                    name="lastName"
                                    label="Apellidos"
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                                <TextField
                                    className='auth-inputs auth-margin-top-inputs'
                                    id="email"
                                    name="email"
                                    label="email"
                                    variant="outlined"
                                    type='email'
                                    onChange={handleChange}
                                />
                                <TextField
                                    className='auth-inputs auth-margin-top-inputs'
                                    id="password"
                                    name="password"
                                    label="Contraseña"
                                    variant="outlined"
                                    type='password'
                                    onChange={handleChange}
                                />
                                <TextField
                                    className='auth-inputs auth-margin-top-inputs'
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    label={error ? "La contraseña no coincide" : "Repetir contraseña **"}
                                    error={error}
                                    variant="outlined"
                                    type='password'
                                    onChange={handleChange}
                                />
                                <Button onClick={submitForm} className='auth-inputs-button' type="button" variant="contained">Crear</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item sm={2.5} md={2.5} xl={1} xs={2} lg={3.5}></Grid>
                </Grid>
            </Box>
        <Box sx={{height: '10vh'}}>
            <Footer/>
        </Box>
    </Box>)
}

export default NewAccount; 