import RoutePrivate from './components/routes/routePrivate';
import tokenAuth from './config/tokenAuth';
import './App.css';
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'

//-------------------<< STATES >>-------------------//
import AuthState from './context/auth/authState';
import SectionState from './context/sections/sectionState';
import ModuleState from './context/modules/moduleState';
import AlertState from './context/alerts/alertState';
import RecipeState from './context/recipes/recipeState';
import FolderState from './context/folders/folderState';


//-----------------<< COMPONENTS >>-----------------//
import Login from './components/auth/Login';
import {RecipesManagement} from './components/recipes';
import Machines from './components/machines';
import Dashboard from './components/dashboard';
import Users from './components/users';
import Profile from './components/profile';
import Settings from './components/settings';
import IngredientState from './context/ingredients/ingredientState';
import NewAccount from './components/auth/NewAccount';
import Content from './components/content';
import Terminos from './components/settings/Terminos';
import Aviso from './components/settings/Aviso';

const token = localStorage.getItem('token');
if(token){ tokenAuth(token)}

const App = () => {
  return (
    <AuthState>
      <AlertState>
        <ModuleState>
          <SectionState>
            <FolderState>
              <RecipeState>
                <IngredientState>
                  <Router>
                    <Routes>
                      <Route path='/' element={<Login />} />
                      <Route path='/new-account' element={<NewAccount/>} />
                      <Route index path='/inicio'element={
                        <RoutePrivate><Dashboard/></RoutePrivate>
                      }/>
                      <Route index path='/recetas'element={
                        <RoutePrivate><RecipesManagement/></RoutePrivate>
                      }/>
                      <Route index path='/settings/profile/:id'element={
                        <RoutePrivate><Profile/></RoutePrivate>
                      }/>
                      <Route index path='/settings/terminos/:id'element={
                        <RoutePrivate><Terminos/></RoutePrivate>
                      }/>
                      <Route index path='/settings/ad/:id'element={
                        <RoutePrivate><Aviso/></RoutePrivate>
                      }/>
                      <Route index path='/machines/:id'element={
                        <RoutePrivate><Machines/></RoutePrivate>
                      }/>
                      <Route index path='/users'element={
                        <RoutePrivate><Users/></RoutePrivate>
                      }/>
                      <Route index path='/content/recommendation/:id'element={
                        <RoutePrivate><Content route="recommendation"/></RoutePrivate>
                      }/>
                      <Route index path='/content/videos/:id'element={
                        <RoutePrivate><Content route="videos"/></RoutePrivate>
                      }/>
                      <Route index path='/content/videos/:id'element={
                        <RoutePrivate><Content route="videos"/></RoutePrivate>
                      }/>
                      <Route path="*" element={<Navigate to="/"/>} />
                    </Routes>
                  </Router>
                </IngredientState>
              </RecipeState>
            </FolderState>
          </SectionState>
        </ModuleState>
      </AlertState>
    </AuthState>);
}

export default App;
