import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import userImg from '../../img/icons/icon_user_1.jfif'
import { Box, Grid, Typography } from '@mui/material';
import { Chart } from 'react-google-charts';

const containerStyle = {
    boxShadow: '0px 3px 7px -4px gray',
    background: 'white',
    borderRadius: '15px',
    textAlign: 'center',
    display: 'grid',
    alignContent: 'center'
}

const Users = () => {
    const data = (num = 2)=> {
        let random = Math.floor(Math.random() * 13)
        
        num = num * random
        console.log(num)
        return([
            [
            "Day",
            "Inicios de sesión",
            "Creación de recetas",
            "Rendimiento",
            ],
            [num*1.0, 1.2*num, num*1.0, num],
            [num*1.1, 3.2*num, num*1.2, num],
            [num*1.2, 1.5*num, num*1.4, num],
            [num*1.3, 2.2*num, num*1.6, num],
            [num*1.4, 0.5*num, num*1.8, num],
            [num*1.5, 3.2*num, num*2.0, num],
            [num*1.6, 1.2*num, num*1.2, num],
            [num*1.7, 2.2*num, num*2.4, num],
            [num*1.8, 2.32*num, num*3.0, num],
            [num*1.9, 3.2*num, num*1.2, num],
            [num*2.0, 0.2*num, num*3.4, num],
            [num*2.1, 0.2*num, num*3.5, num],
            [num*2.2, 2.2*num, num*3.7, num],
            [num*2.3, 3.2*num, num*1.9, num],
        ])
    }
      
    const options = {
        chart: {
          title: "Rendimiento del usuario",
          subtitle: "in millions of dollars (USD)",
        },
    };

    const [select, setSelect] = useState(false)
    const [roles, setRoles] = useState([
        "Administrador",
        "Lider",
        "Ayudante de cocina",
        "Personal"
    ])
    const [users, setUsers] = useState([
        {
            name: 'Milton',
            lastname: "Ramos",
            phone: '+528112707085',
            job: 'Desarrollador',
            createAt: '11/05/2023',
            email: 'milton.ramos.pro@gmail.com',
            isDeleted: false,
            rol: 0,
            random: Math.floor(Math.random() * 51)
        },
        {
            name: 'Luis',
            lastname: "Gonzalez",
            phone: '+528180960101',
            job: 'Ing. Producción',
            createAt: '11/11/2021',
            email: 'l.gonzalez@proservicios.com',
            isDeleted: false,
            rol: 1,
            random: Math.floor(Math.random() * 51)
        },
        {
            name: 'Marco',
            lastname: "Elizondo",
            phone: '+528125530959',
            job: 'Diseñador',
            createAt: '20/12/2022',
            email: 'm.elizondo@proservicios.com',
            isDeleted: false,
            rol: 2,
            random: Math.floor(Math.random() * 51)
        }
    ])

    useEffect(()=>{console.log(select)})
    const [display, setDisplay] = useState("Default")
    return (<Grid container spacing={4}>
        <Grid item xs={4.5}>
            <Box
                className='tabScrollStyle'
                sx={{
                    ...containerStyle,
                    height: '78vh',
                    padding: '10% 5%',
                    overflowY: 'scroll'
            }}>
                {display === 'edit' ? (<EditUser user={select || select > -1 ? users[select]: select}/>) :(<Box sx={{ display: 'grid', alignContent: 'center'}}>
                    <Typography sx={{color:'#b3b3b3'}}>Selecciona un usuario para editarlo</Typography>
                </Box>)}
            </Box>
        </Grid>
        <Grid item xs={7.5} className='tabScrollStyle' sx={{ height:'78vh', overflowY: 'scroll', marginTop: '30px'}}>
            <Box sx={{marginRight: '20px'}}>
                <Box sx={{...containerStyle, marginBottom: '20px', padding: '3% 5%'}}>
                    {select !== false ? (<Chart
                        chartType="Line"
                        width="90%"
                        height="50vh"
                        data={data(users[select]?.random)}
                        options={options}
                    />):null}
                </Box>
            </Box>
            <Box sx={{marginRight: '20px', marginBottom: '30px'}}>
                <TableContainer component={Paper} className='tabScrollStyle'>
                    <Table size="small" aria-label="a dense table">
                    <TableHead sx={{backgroundColor: '#445056'}}>
                        <TableRow sx={{'& th':{color: 'white !important'}}}>
                            <TableCell>Nombre</TableCell>
                            <TableCell align="right">Telefóno</TableCell>
                            <TableCell align="right">Correo</TableCell>
                            <TableCell align="right">Puesto</TableCell>
                            <TableCell align="right">Fecha de creación</TableCell>
                            <TableCell align="right">Deshabilitado</TableCell>
                            <TableCell align="right">Rol</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user, i) => (<TableRow
                            onClick={()=>{
                                setSelect(i);
                                setDisplay("edit");
                            }}
                            key={user.name}
                            sx={{
                                cursor: 'pointer',
                                background: select === i ? '#e7e7e7': 'transparent',
                                '&:last-child td, &:last-child th': { border: 0 }
                        }}>
                            <TableCell component="th" scope="row">{`${user.name} ${user.lastname}` }</TableCell>
                            <TableCell align="right">{user.phone}</TableCell>
                            <TableCell align="right">{user.email}</TableCell>
                            <TableCell align="right">{user.job}</TableCell>
                            <TableCell align="right">{user.createAt}</TableCell>
                            <TableCell align="right">{`${user.isDeleted}`}</TableCell>
                            <TableCell align="right">{roles[user.rol]}</TableCell>
                        </TableRow>))}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Grid>
    </Grid>);
}

const EditUser = ({user}) => {
    const [roles, setRoles] = useState([
        "Administrador",
        "Lider",
        "Ayudante de cocina",
        "Personal"
    ])

    const FieldData = ({name = '', value = ''}) => (<Grid sx={{marginY: '8px'}} container spacing={2}>
        <Grid item xs={4} sx={{
            marginTop: '10px',
            textAlign: 'end',
            padding: '0px !important'
        }}><Typography sx={{fontWeight: 'bold', paddingRight: '8px'}}>{name}:</Typography></Grid>
        <Grid item xs={8}
            sx={{
                marginTop: '10px',
                padding: '0px !important',
                textAlign: 'start'
            }
        }>
            <Typography>{value}</Typography>
        </Grid>
    </Grid>)

    return(<Box>
        <img style={{
            width: '70%',
            borderRadius: '50%',
            boxShadow: '0px 2px 10px -5px black'
        }} width='100%' src={userImg}/>
        <FieldData name='Nombre' value={`${user.name} ${user.lastname}`}/>
        <FieldData name='Telefóno' value={user.phone}/>
        <FieldData name='Correo' value={user.email}/>
        <FieldData name='Puesto' value={user.job}/>
        <FieldData name='Rol' value={roles[user.rol]}/>
    </Box>)

}

export default Users;