import React, {useState, useEffect, useContext} from 'react'
import { Box, Grid, Typography, Button, MenuItem, TextField, Rating} from '@mui/material';
import { VerticalTabs, MediaCard as CardForm, Icons, Slider } from "../../material";
import imgSrc from '../../../img/food-1.jpg'
import { SteppersSpecial, Ingredients } from '../elements';
import RecipeContext from '../../../context/recipes/recipeContext';

const EditForm = ({recipe = null, close = ()=>console.log("close")}) => {
    const recipeContext = useContext(RecipeContext);
    const { modificarReceta } = recipeContext;
    let typesRecipes = [
        'Carnes', 'Aves', 'Pescado', 'Panaderia', 
        'Huevo', 'Guarniciones', 'Grill', 'Reheat'
    ];

    let stepFilter = {};
    let paramsActives = [];
    const [process, setProcess] = useState({});
    const [precalentamiento, setPrecalentamiento] = useState(false)
    const [mode, setMode] = useState(1);
    const [activeStep, setActiveStep] = useState(100);
    const [steps, setSteps] = useState([]);
    const [params, setParams] = useState([]);
    const [data, setData] = useState({})
    let parameters = [
        {name: "Nivel de humedad", icon: 'Humedad', color: '#7bcaef'},
        {name: "Ajuste de tiempo", icon: 'Reloj', color: '#8e9092'},
        {name: "Nivel de temperatura", icon: 'Termometro', color: '#db5a1d'},
        {
            name: "Nivel de ventilación",
            icon: 'Ventilador',
            color: 'linear-gradient(to right, #70c09d, #c4fde4)',
            colorLetter: '#70c09d',
            type: 'defined'
        },
    ]

    const changeParams = e => { setParams({...params, [e.target.name]: e.target.value}) }

    useEffect(()=>{
        console.log(recipe)
        const fnAsync = async () => {
            try{ if('steps' in recipe){ 
                setSteps(await recipe.steps)
                setMode(await recipe.typeRecipe + 1)
            }
            }catch(err){console.log(err)}
        }
        fnAsync()
    },[])

    useEffect(()=>{
        const fnAsync = async () => {
            if(await steps.length > 0){
                if(await steps && await activeStep !== 100){
                    paramsActives = await steps[activeStep]
                    if('params' in paramsActives){
                        setParams(await paramsActives.params)
                    }else{setParams([])}
                }else{setParams([])}
            }
        }
        fnAsync();
    },[activeStep])

    useEffect(()=>{
        const fnAsync = async () => {
            if(activeStep !== 100){
                stepFilter = await steps
                stepFilter[activeStep] = await {...steps[activeStep], params};
            }
        } 
        fnAsync();
    },[params])
    
    useEffect(()=>{
        if(activeStep !== 100){
            stepFilter = steps
            stepFilter[activeStep] = {...steps[activeStep], params}
            setSteps(stepFilter)
            setData({...data, steps})
        }
    },[params])

    return(<Grid container spacing={2} sx={{ 
        margin: '0px !important', 
        width: '100%', 
        position: 'relative'
    }}>
        <Grid sx={{padding: '0px !important'}} item xs={3}>
            <img src={imgSrc} style={{
                width: '100%',
                height: '90vh',
                objectFit: 'cover',
            }}/>
        </Grid>
        <Grid item xs={9} sx={{ height: '90vh', padding: '0px !important' }}>
            {recipe ? (<Box>
                <Typography variant='h4' sx={{textAlign: 'center', paddingTop: '15px'}}>
                    {recipe.name ? recipe.name :'No Name'}
                </Typography>
                <VerticalTabs hx='79vh' columns={{xs: 3, sm: 3,  md: 3, lg: 3, xl: 3}}
                    tabs={[
                        {name: "Procedimiento", render: (<CardForm>
                            <Box>
                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <Box>
                                            <Typography sx={{textAlign: 'left', marginBottom: '20px', fontSize: '12px'}}>Selecciona un tipo de cocción</Typography>
                                            <Grid container spacing={2}>
                                                {['Conveccion','Combi','Vapor'].map((conv, i) => {
                                                    return(<Grid sx={{textAlign: 'center'}} item xs={4}>
                                                        <Button 
                                                            disabled = {data.steps && activeStep !== 100 ? false : true }
                                                            name='mode'
                                                            onClick={e=>{
                                                                setMode(i + 1);
                                                                setData({...data, convection: i + 1})
                                                                setParams({...params, mode: i + 1})
                                                            }}
                                                            value={1}
                                                            className={`recipe-btn${params.mode === i + 1 ? '-select' : ''} btn-${data.steps && activeStep !== 100 ? 'avalaible': 'disabled'}`}
                                                        >
                                                            <Icons dimensions={{w: '40', h: '40'}} name={conv}/>
                                                        </Button>
                                                    </Grid>)
                                                })}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SteppersSpecial states={{ steps, activeStep, process}}
                                            actions={{setSteps, setActiveStep, setProcess}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{marginTop: '10px'}} container spacing={5}>
                                    {parameters ? parameters.map((parameter, num) => (<Grid
                                        sx={{textAlign: 'center'}}
                                        item xs={6}
                                    >
                                        <Typography sx={{textAlign: 'left', fontSize: '12px'}}>{parameter.name}</Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <Button><Icons dimensions={{w: 30, h: 30}} name={parameter.icon}/></Button>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Slider
                                                    disabled = {activeStep === 100? true : false}
                                                    name={parameter.icon}
                                                    value={params ? params[parameter.icon]: false}
                                                    onChange={changeParams}
                                                    color={parameter.color}
                                                    type={'type' in parameter ? parameter.type : 'standard'}
                                                    colorLetter={'colorLetter' in parameter ? parameter.colorLetter : null}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>)): null}
                                </Grid>

                            </Box>
                        </CardForm>)},
                        {name: "Información General", render: (<Box>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Typography sx={{fontSize:'13px'}}>Tipo de platillo</Typography>
                                    {typesRecipes ? (<Box
                                            sx={{
                                                padding: '3% !important',
                                                display: 'inline-flex',
                                                alignSelf: 'center'
                                        }}>
                                            <Icons dimensions={{w: '30', h: '30'}} name={typesRecipes[recipe.saucer]} />
                                            <Typography sx={{marginLeft: '15px'}}>{typesRecipes[recipe.saucer]}</Typography>
                                        </Box>): null}
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid sx={{textAlign: 'right'}} item xs={4}>
                                    <Typography sx={{fontSize:'13px'}}>Valoración</Typography>
                                    <Rating value={2} name="read-only"  readOnly/>
                                </Grid>
                            </Grid>
                            <Box>
                                <Typography sx={{fontSize:'13px', marginTop: '20px'}}>Descripción</Typography>
                                <Typography>{recipe.description ? recipe.description : null}</Typography>
                            </Box>
                        </Box>)},
                        {name: "Ingredientes", render: (<Ingredients list={recipe.ingredients}/>)},
                        {name: "Notas", render: (<Box>
                            <Typography variant='h5' sx={{marginBottom: '10px'}}>Notas</Typography>
                            <Typography sx={{
                                wordBreak: 'break-word',
                                textAlign: 'center'
                            }}>
                                {'notes' in recipe ? recipe.notes !== ''? recipe.notes : 'No hay notas existentes': null}
                            </Typography>
                        </Box>)}
                    ]}
                />
            </Box>) :'No Recipe'}
        </Grid>
        <Box sx={{
            position: 'absolute',
            bottom: '10px',
            right: '10px'
        }}>
            <Button 
                onClick={async ()=>{
                    modificarReceta({
                        _id: recipe._id,
                        steps
                    });
                    close();  
                }}
                sx={{
                    background:'#445056',
                    '&:hover':{ background:'#d8b915'}
                }}
                variant="contained"
                endIcon={<Icons name='Save'
            />}>Editar</Button>
        </Box>
    </Grid>)
}

export default EditForm;