import React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderThumb } from '@mui/material/Slider';
import {Tooltip, Box} from '@mui/material';

const ValueLabelComponent = (props) => {
  const { children, value } = props;
  return (<Tooltip enterTouchDelay={0} placement="top" title={value}>{children}</Tooltip>);
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const AirbnbThumbComponent = (props) => {
    const { children, ...other } = props;
    return(<SliderThumb {...other}>
        {children}
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
        <span className="airbnb-bar" />
    </SliderThumb>);
}

AirbnbThumbComponent.propTypes = {children: PropTypes.node};
const valuetext = (value) => {return `Nivel ${value}`;}

export default function CustomizedSlider({
    color,
    value=0,
    name = 'default',
    colorLetter,
    type='default',
    disabled = false,
    onChange=()=>console.log("Slider")
}) {
    const PrettoSlider = {
        color: color ? color : '#52af77',
        height: 8,
        '& .MuiSlider-track': {
            border: 'none',
        },
        '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': { boxShadow: 'inherit' },
            '&:before': { display: 'none' }
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: color ? color : '#52af77',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': { transform: 'translate(50%, -100%) rotate(-45deg) scale(1)' },
            '& > *': { transform: 'rotate(45deg)' }
        },
    };
  
    return (<Box>
        {type ? (<Box>
            {type === 'defined' ? (
                <Slider
                    sx={{
                        color: colorLetter ? colorLetter : '#52af77',
                        height: 8
                    }}
                    getAriaValueText={valuetext}
                    name={name}
                    value={value}
                    defaultValue={value}
                    onChange={onChange}
                    marks
                    min={1}
                    max={4}
                    aria-label="Always visible"
                    valueLabelDisplay="auto"
                />):
                (<Slider
                    disabled = {disabled}
                    sx={PrettoSlider}
                    name={name}
                    min={0}
                    max={name !== "Termometro" ? 100: 300}
                    onChange={onChange}
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    value={value}
                    defaultValue={value}
                />)
            }
        </Box>) : null}
    </Box>);
}