import '../../styles/material.css'
import React, {Fragment, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { styled as styledSpecial } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import alertsContext from '../../context/alerts/alertContext';
import {useNavigate} from 'react-router-dom';
import ButtonImg from './ButtonImg';
import IconsComponent from './Icons';
import AuthContext from '../../context/auth/authContext';
import SliderComponent from './Slider';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import TabsV from './TabsV';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuSpecial from './Menu';
import styled from '@emotion/styled';
import { 
  Box, ListItemButton, Typography, Card, CardContent, Skeleton, Stack,
  AccordionSummary as MuiAccordionSummary, InputBase, IconButton, Alert,
  Snackbar, Badge, AccordionDetails as MuiAccordionDetails, Tooltip, Menu,
  Accordion as MuiAccordion, Tabs, Tab, Paper, Modal, Toolbar, Avatar, MenuItem, Grid, ListItemText
} from '@mui/material';
import {
  Search as SearchIcon, Close as CloseIcon, Notifications as NotificationsIcon, Settings
} from '@mui/icons-material';
import SplitButton from './SplitButton';

export const AccordionSpecialComponent = ({components}) =>{
    const Accordion = styledSpecial((props) => (<MuiAccordion disableGutters elevation={0} square {...props} />))
        (({ theme }) => ({
            border: `1px solid ${theme.palette.divider}`,
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
        }
    ));
    
    const AccordionSummary = styledSpecial((props) => (
      <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
      />
    ))(({ theme }) => ({
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, .05)'
          : 'rgba(0, 0, 0, .03)',
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
    }));
    
    const AccordionDetails = styledSpecial(MuiAccordionDetails)(({ theme }) => ({
      padding: theme.spacing(2),
      borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));
    
    const AccordionSpecial = () => {
      const [expanded, setExpanded] = React.useState('panel1');
    
      const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };
    
      console.log(components)
      return (<Box>
        {components ? (<Box>
            {components.map((component, i) => (<Accordion expanded={expanded === `panel${i+1}`} onChange={handleChange(`panel${i+1}`)}>
                <AccordionSummary aria-controls={`panel${i+1}d-content`} id={`panel${i+1}d-header`}>
                <Typography>{component.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {component.render ? component.render : "no content"}
                </AccordionDetails>
            </Accordion>))}
        </Box>): null}
      </Box>);
    }

    return AccordionSpecial
}

export const MediaCard = ({children, typeCard}) => {
    switch(typeCard){
        case 'minimal':
            return (
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  {children}
                </CardContent>
              </Card>
            );
        default:
            return (
                <Card sx={{ width: '100%', marginBottom: '30px' }}>
                  <CardContent>
                    {children}
                  </CardContent>
                </Card>
              );
    }
}

export const SkeletonForm = ({type}) => {
    switch(type){
        case 0:
            return(<Stack spacing={1}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={'100%'} height={60} />
                <Skeleton variant="rounded" width={'100%'} height={60} />
            </Stack>)
        default: 
            return(<Box>
                <Typography>Type Error</Typography>
            </Box>)
    }
}

export const ButtonSearch = () => {
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
    >
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Buscar Receta"
        inputProps={{ 'aria-label': 'Buscar receta' }}
      />
    </Paper>
  );
}

export const TabsSpecial = () => {
    const TabPanel = props => {
      const { children, value, index, ...other } = props;
    
      return (
        <Box
            role="tabpanel"
            key={index}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box sx={{ p: 3 }}>{children}</Box>)}
        </Box>
      );
    }
    
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };
    
    const a11yProps = (index) => {
      return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` };
    }
    
    const BasicTabs = ({tabsArray, sx, children}) => {
        const [value, setValue] = useState(0);
        const handleChange = (event, newValue) => {setValue(newValue);};
    
        return (<Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    sx={{
                        display: 'grid',
                        justifyContent: 'center'
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="Componente tabs"
                >
                    {tabsArray ? tabsArray.map((tabArray, i) =>
                        (<Tab key={i} label={tabArray.name} {...a11yProps(i)} />)
                    ): null}
                </Tabs>
            </Box>
            <Box sx={{marginY: '10px'}}>
                <ButtonSearch />
            </Box>
            <Box className='tabScrollStyle' sx={sx ? sx : {}}>
                {tabsArray ? tabsArray.map((tabArray, i) =>
                    (<TabPanel value={value} key={`tabpanel${i}`} index={i}>
                        {tabArray ? tabArray.children : null}
                    </TabPanel>)
                ): null}
            </Box>
        </Box>);
    }

    return BasicTabs;
}

export const ImageIcon = (url, dimensions) => (
    <svg className='test-icon-svg' width={dimensions.w} height={dimensions.h}>
        <image href={url} width={dimensions.w} height={dimensions.h}/>
    </svg>
)

export const Advertence = ({msg, type}) => {
    const alertContext = useContext(alertsContext);
    const { alert } = alertContext;

    const [open, setOpen] = useState(false)

    useEffect(()=>{
        if(alert){
            setOpen(true)
            return;
        }
        setOpen(false)
        return;
    },[alert]);

    const action = (
        <Fragment>
            <IconButton size="small" aria-label="close" color="inherit">
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    return (<Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar severity="error" open={open} action={action}>
            <Alert
                severity={type ? type : "error"}
                sx={{ width: '100%'}}> {alert ? alert.msg : ""}
            </Alert>
        </Snackbar>
    </Stack>);
}

export const BasicModal = ({ className = "", state, sx, title, classDisabled = false,
  children = (<Typography>Content</Typography>)
}) => {
  if(!state) return;
  const { open, setOpen } = state;
  const handleClose = () => setOpen(false);

  return (<Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
      <Box 
        sx={sx}
          className={classDisabled ? `${className}`: `${className} tabScrollStyle`
      }>
          {title ? (<Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
          >{title}</Typography>): null}
          <Box>{children}</Box>
      </Box>
  </Modal>);
}

export const ToolbarMain = ({open, handleDrawerAction}) => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext);
  const { cerrarSesion, usuario } = authContext;

  const [themeTop, setThemeTop] = useState(0)
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = e => { setAnchorElNav(e.currentTarget) };
  const handleOpenUserMenu = e => { setAnchorElUser(e.currentTarget) };
  const handleCloseNavMenu = () => { setAnchorElNav(null) };
  const handleCloseUserMenu = () => { setAnchorElUser(null) };

  const pages = ['Products', 'Pricing', 'Blog'];
  const settings = [
    { name: 'Ajustes', route: '/settings', icon: (<Settings sx={{marginRight: '10px'}}/>) },
    { name:'Cerrar Sesión', action: () => cerrarSesion(), icon: (<ExitToAppIcon sx={{marginRight: '10px'}} />) }
  ];

  useEffect(()=>{
    if(usuario){
      setThemeTop(usuario?.typeTheme);
      return;
    }
    console.log('no usuario')
  },[usuario])

  return(<Toolbar id="rlmdsñledplñlassa" sx={{
    background: `var(--top-${themeTop || themeTop===0 ? themeTop + 1: 1}-color) !important`,
    minHeight: "35px !important",
    padding: "0px 10px !important",
    marginRight: "0px",

  }}>
    <IconButton
      color="black"
      aria-label="open drawer"
      onClick={() => handleDrawerAction(true)}
      edge="start"
      sx={{ height: "12px", mr: 2, ...(open && { display: 'none' })}}
    >
      <MenuIcon sx={{fontSize: '18px !important'}}/>      
    </IconButton>
    <IconButton
      color="black"
      aria-label="open drawer"
      onClick={() => handleDrawerAction(false)}
      edge="start"
      sx={{ height: "12px", mr: 2, ...(!open && { display: 'none' })}}
    >
      <MenuOpenIcon sx={{fontSize: '18px !important'}}/>
    </IconButton>
    <Box sx={{textAlign: 'end', flexGrow: {md: 'flex'} }}></Box>
    <Box sx={{textAlign: 'end', flexGrow: 1 }}>
      <Box sx={{paddingRight: '20px'}}>
        <Badge sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 } }} badgeContent={4} color="secondary">
          <NotificationsIcon fontSize='12px' color="action" />
        </Badge>
      </Box>
    </Box>
    <Box sx={{ flexGrow: 0, position: 'relative' }}>
      <Box id="io3ioioioioioioio" sx={{
        opacity: Boolean(anchorElUser) ? 1:0,
        transition: "opacity 290ms",
        position: 'absolute',
        background: 'transparent !important',
        right: '10.5px',
        top: '39px',
        width: 0,
        height: 0,
        borderLeft: "10px solid transparent",
        borderTop: "10px solid transparent",
        borderRight: "10px solid white",
        borderBottom: "10px solid white"
      }}/>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar sx={{fontSize: "12px", height: "23px", width: "23px"}} alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ml: '-10px', mt: '48px', "& .MuiList-root" : {paddingY: '0px'}}}
        PaperProps={{
          sx: {
            borderRadius: '0px 0px 0px 10px !important',
            boxShadow: '-10px 10px 15px -10px black',
            width: '20%',
            minWidth: '130px',
            "& p": {
              fontFamily: "Monserrat ProServicios"
            }
          }
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box sx={{textAlign: 'center', paddingY: '15px', background: 'linear-gradient(0deg, #3f4b501c, white)'}}>
          <IconButton sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          </IconButton>
          <Typography sx={{fontWeight: 'bold', marginTop: '10px', color:'#505050'}}>{usuario?.firstName} {usuario?.lastName}</Typography>
        </Box>
        {settings.map(setting =>{
          return(<MenuItem sx={{padding: 0}} key={setting.name} onClick={handleCloseUserMenu}>
            {setting.action ? (<ListItemButton
              onClick={setting.action}
              sx={{
                textAlign:"center",
                fontFamily: 'Monserrat ProServicios'
              }}
            >
              {setting?.icon} {setting?.name}
            </ListItemButton>):
            setting.route ? (
              (<ListItemButton sx={{fontFamily: 'Monserrat ProServicios'}}
                onClick={()=>navigate(setting.route)}
              >{setting?.icon} {setting?.name}</ListItemButton>)): 
            (<ListItemButton sx={{textAlign:"center", fontFamily: 'Monserrat ProServicios'}}>
              {setting?.icon} {setting?.name}</ListItemButton>)}
          </MenuItem>)
        })}
      </Menu>
    </Box>
  </Toolbar>)}

export const ImageAttach = styled.input`
  border: 10000px solid transparent;
  cursor: pointer;
  font-size: 10000px;
  margin: 0;
  opacity: 0;
  outline: 0 none;
  padding: 0;
  position: absolute;
  right: -1000px;
  top: -1000px;
`

export const ContainerImage = styled.div`
  color: #b3b3b3;
  border: 3px dashed #b3b3b3;
  cursor: pointer;
  margin: 5vh 0px ;
  font-size: 18px;
  font-weight: bold;
  min-height: 15px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  display: grid;
  align-items: center;
  height: 20vw;
`
export const Footer = () => (<Grid container sx={{boxShadow: '0px 0px 8px -5px black'}}>
  <Grid item xs={2} className='footer-grid footer-no-padding'>
    <Icons name='LogoBlack' dimensions={{w:80, h:80}}/>
  </Grid>
  <Grid item xs={4} className='footer-no-padding'>
    <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Politicas y Condiciones</Typography>
  </Grid>
  <Grid item xs={2} className='footer-no-padding'>
    <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Soporte Técnico</Typography>
  </Grid>
  <Grid item xs={2} className='footer-no-padding'>
    <Grid container >
      <Grid item xs={4} sx={{justifyContent: 'center'}} className='footer-no-padding'>
        <Icons name='Facebook'/>
      </Grid>
      <Grid item xs={4} sx={{justifyContent: 'center'}} className='footer-no-padding'>
        <Icons name='Instagram'/>
      </Grid>
      <Grid item xs={4} sx={{justifyContent: 'center'}} className='footer-no-padding'>
        <Icons name='Tiktok'/>
      </Grid>
    </Grid>
  </Grid>
  <Grid item xs={2} className='footer-no-padding'>
    <Typography sx={{textAlign: 'center', fontSize: '12px'}}>© 2023 Kook Works</Typography>
  </Grid>
</Grid>)

export const BtnImg = ButtonImg;
export const Icons = IconsComponent;
export const Slider = SliderComponent;
export const VerticalTabs = TabsV;
export const MenuComponent = MenuSpecial;
export const SplitComponent = SplitButton