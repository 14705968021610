import * as React from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';

export default function CardOption({url="", label="Default"}) {
  return (
    <Card sx={{ borderRadius: '10px !important', maxWidth: 345, margin: '10%', cursor: 'pointer' }}>
      <CardMedia
        sx={{ height: 140 }}
        image={url}
        title="green iguana"
      />
      <CardContent>
        <Typography sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
            {label}
        </Typography>
      </CardContent>
    </Card>
  );
}