import { MOSTRAR_SECCIONES } from '../../types'

export default( state, action) => {
    switch(action.type){
        case MOSTRAR_SECCIONES:
            return { secciones: action.payload };
        default:
            return state;
    }
}
