import { CREAR_MODULO, MOSTRAR_MODULOS, SELECCIONAR_MODULO } from '../../types'

export default( state, action) => {
    switch(action.type){
        case MOSTRAR_MODULOS:
            return { modulos: action.payload };
        case CREAR_MODULO:
            return { ...state, 
                modulos: [
                    ...state.modulos,
                    action.payload
            ] };
        case SELECCIONAR_MODULO:
            return {
                ...state,
                moduloSeleccionado: action.payload
            }
        default:
            return state;
    }
}
