import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import imgSrc from '../../../img/food-1.jpg'
import IngredientContext from "../../../context/ingredients/ingredientContext";
import { Favorite as FavoriteIcon, Share as ShareIcon } from '@mui/icons-material';
import dateFormat from '../../material/functions/dateFormat';
import AddIcon from '@mui/icons-material/Add';
import manual from '../../../img/icons/recipe-icon-manual.png';
import folderImg from '../../../img/icons/folder-recipe.png';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
    Box, Grid, Typography, TextField, MenuItem,
    Card, CardHeader, CardMedia, Stepper,
    Step, StepButton, CardActions, IconButton,
    StepLabel, styled, List, ListItem, ListItemText
} from '@mui/material';
import { Icons, BasicModal as ModalForm, SkeletonForm } from '../../material';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '../forms/Edit';
import Create from '../forms/Create';

export const Loading = ({section}) =>{
    switch(section){
        case 'recipes':
            return(<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                        <SkeletonForm type={0}/>
                    </Grid>
                </Grid>
            </Grid>)
        default:
            return(<Box>
                <Typography>Loading...</Typography>
            </Box>)
    }
}

export const EditForm = Edit;
export const NewRecipe = Create;

export const Ingredients = ({list}) => {
    console.log(list)
    const ingredientContext = useContext(IngredientContext);
    const { ingredientes } = ingredientContext;
    let filterIng = ingredientes.data; 

    useState(()=>{
        if(filterIng.length){ console.log(filterIng) }
    },[ingredientes])

    return(<Box>
        <Typography variant='h5'>Ingredientes</Typography>
        <List>
            {list.length > 0 ? list.map((ingredient, i) => (<Grid container>
                <ListItem key={i} divider>
                <Grid xs={11}>
                    <ListItemText sx={{
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                        overflow: 'hidden'
                    }} primary={'nameIndredient' in ingredient ?
                            `${ingredient.quantity} ${ingredient.nameIndredient}`:
                        'Empty'}
                    />
                </Grid>
                <Grid xs={1} sx={{textAlign: 'center'}}>
                    <DeleteIcon
                        sx={{cursor: 'pointer'}}
                        // onClick={()=>{
                        //     setList(list.filter((ing, num) => num !== i ))
                        // }}
                    />
                </Grid>
                </ListItem>
            </Grid>)): (<Typography sx={{textAlign: 'center'}}>No hay ingredientes registrados</Typography>)}
        </List>
    </Box>)
}

export const SteppersSpecial = ({states, actions}) =>{
    const preRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
  
    useLayoutEffect(() => {
      setWidth(preRef.current.offsetWidth);
      setHeight(preRef.current.offsetHeight);
    }, []);

    useEffect(() => {
        function handleWindowResize() {
          setWidth(preRef.current.clientWidth);
          setHeight(preRef.current.clientHeight);
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    let {steps, activeStep, process} = states;
    let {setSteps, setActiveStep, setProcess} = actions;
    const [completed, setCompleted] = useState({});
    const [precalentamiento, setPrecalentamiento] = useState(false)

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 10,
          left: 'calc(-50% + 16px)',
          right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            border: '2px solid linear-gradient(to right, #9e9e9e, #1976d2)', //Creas el borde
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            border: '2px solid linear-gradient(to right, #9e9e9e, #1976d2)', //Creas el borde
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderTopWidth: 3,
          borderRadius: 1,
        },
      }));

    const handleStep = (step) => () => { setActiveStep(step) };

    return (
        <Box sx={{ width: '100%' }}>
        <Box sx={{marginY: '15px'}}>
            <Typography sx={{fontSize: '12px'}}>Pasos</Typography>
            <Stepper activeStep={activeStep} nonLinear connector={<QontoConnector />}>
                {steps.map((step, index) => (<Step key={step.name} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}/>
                </Step>))}
                <Step sx={{cursor: 'pointer'}} key='add' onClick={()=>{ 
                    // setActiveStep(100)
                    if(steps.length + 1 < 8){
                        setSteps([...steps, process])
                        setProcess({})
                    }
                }}>
                    <StepLabel sx={{
                        color: '#9e9e9e',
                        borderRadius: '50%',
                        border: '2px solid #9e9e9e',
                        '& .MuiStepLabel-iconContainer':{
                            paddingRight: '0px !important'
                        }
                    }} StepIconComponent={AddIcon}></StepLabel>
                </Step>
            </Stepper>
        </Box>
        <Grid sx={{marginTop: '30px', paddingLeft: '48px'}} container spacing={6}>
            <Grid sx={{
                    display: 'grid',
                    alignContent: 'center',
                    padding: '0px !important'
            }} item xs={3} sm={3} md={3} lg={6}>
                <Typography ref={preRef} 
                    sx={{wordBreak: 'break-word', fontSize: '12px', width: '80%'}}
                    className={`recipe-precalentamiento${precalentamiento ? '-active' : ''} ${activeStep!==0 ? 'disabled':''}`}
                    onClick={()=>{if(activeStep === 0){ setPrecalentamiento(!precalentamiento) }}}
                >
                    {width>116 ? 'Precalentamiento':(<Icons name='Precalentamiento'/>)}
                </Typography>
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={6} sx={{padding: '0px !important'}}>
                <TextField
                    id='action'
                    className='recipe-action-box'
                    select
                    label="Acción"
                    defaultValue='Pincelar'
                    variant="standard"
                    fullWidth
                >
                    {['Cortar', 'Pincelar', 'Añadir ingrediente', 'Añadir liquido'].map((option) => (
                        <MenuItem key={option} value={option}>
                            <Icons name={option}/><Typography sx={{marginLeft: '8px'}}>{option}</Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    </Box>);
}

export const RecipeForm = ({ check, recipe, actions = null, deleteRecipe }) => {
    const [recipeView, setRecipeView] = useState(0);
    let {state, action} = actions;
    let component = null; 
    let containerText = null;

  const handleLikeFavs = async () =>{
    if(actions){ await action({...state, [`fav_${recipe._id}`]: state[`fav_${recipe._id}`] ? false: true}) }
  }
    const handleMouseEnter = async e => {
      component = await document.getElementById(`card-media-recipe-${recipe._id}`);
      containerText = await document.getElementById(`text-container-${recipe._id}`);
      if(await component && await containerText){
        component.classList.add('select-card-media');
        containerText.classList.remove('select-text-hidden');
      }
    };
    
    const handleMouseLeave = async e => {
      component = await document.getElementById(`card-media-recipe-${recipe._id}`);
      containerText = await document.getElementById(`text-container-${recipe._id}`);
      if(await component && await containerText){
        component.classList.remove('select-card-media');
        containerText.classList.add('select-text-hidden');
      }
    };

    let d = dateFormat(recipe.modificateAt)

    return (<Card
      sx={{
        marginX: '10px',
        width: '100%',
        justifySelf: 'center',
        height: 'fit-content',
        position: 'relative'
    }}>
      <Box sx={{
        position:'absolute',
        background: 'white',
        borderRadius: '0px 0px 0px 100%',
        height:'65px',
        width: '25%',
        top: '0px',
        right: '0px',
        display: 'grid',
        justifyContent: 'end',
        padding: '3% 5%',
        boxShadow: '-1px 1px 14px -5px black'
      }}>
        <img src={manual} height='35px' width='25px'/>
      </Box>
        <ModalForm className='edit-form-container-modal' state={{open: recipeView, setOpen: setRecipeView}}>
          <EditForm recipe={recipe} close={()=>{setRecipeView(false)}}/>
        </ModalForm>
      <Box sx={{ height: "194px", width: '100%', background: 'black' }}>
        <CardMedia
          id={`card-media-recipe-${recipe._id}`}
          component="img"
          height="194"
          sx={{width: '-webkit-fill-available'}}
          image={imgSrc}
          alt="Paella dish"
        />
      </Box>
      <Box
        name={`container-${recipe._id}`}
        onMouseEnter={e => {handleMouseEnter(e)}}
        onMouseLeave={e => handleMouseLeave(e)}
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          padding: '5%',
          height: '-webkit-fill-available',
          cursor: 'pointer'
      }}>
        <Box className='select-text-hidden' id={`text-container-${recipe._id}`}>
          <Grid container spacing={2}>
            <Grid item xs={8} onClick={()=>setRecipeView(true)} cursor='pointer'>
              <CardHeader
                sx={{
                  width: '100%',
                  color: 'white !important',
                  padding: 0
                }}
                title={recipe.name}
              />
            </Grid>
            <Grid sx={{textAlign: 'end'}} item xs={4}>{check}</Grid>
          </Grid>
          <Typography onClick={()=>setRecipeView(true)} cursor='pointer' color={'white'} variant='h7'>{d}</Typography>
          <CardActions
            sx={{
                justifyContent: 'end',
                position: 'absolute',
                bottom: '0px',
                right: '0px'
            }} disableSpacing>
            <IconButton aria-label="add to favorites">
              <FavoriteIcon 
                onClick={handleLikeFavs}
                sx={{color: actions ? state ? state[`fav_${recipe._id}`] ? 'red' : 'white' : 'white' : 'white'}}
              />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon sx={{color: 'white'}}/>
            </IconButton>
            <IconButton onClick={()=>deleteRecipe(recipe._id)} aria-label="share">
              <DeleteIcon sx={{color: 'white'}}/>
            </IconButton>
          </CardActions>
        </Box>
      </Box>
    </Card>);
}

export const FolderForm = ({ check, recipe, actions = null, deleteRecipe }) => {
  let component = null; 
  let containerText = null;

  const handleMouseEnter = async e => {
    component = await document.getElementById(`card-media-recipe-${recipe._id}`);
    containerText = await document.getElementById(`text-container-${recipe._id}`);
    if(await component && await containerText){
      component.classList.add('select-card-media');
      containerText.classList.remove('select-text-hidden');
    }
  };
  
  const handleMouseLeave = async e => {
    component = await document.getElementById(`card-media-recipe-${recipe._id}`);
    containerText = await document.getElementById(`text-container-${recipe._id}`);
    if(await component && await containerText){
      component.classList.remove('select-card-media');
      containerText.classList.add('select-text-hidden');
    }
  };

  let d = dateFormat(recipe.modificateAt)

  return (<Card
    sx={{
      width: '100%',
      justifySelf: 'center',
      height: 'fit-content',
      position: 'relative'
  }}>
    <Box sx={{
      position:'absolute',
      background: 'white',
      borderRadius: '0px 0px 0px 100%',
      height:'65px',
      width: '25%',
      top: '0px',
      right: '0px',
      display: 'grid',
      justifyContent: 'end',
      padding: '4% 4%',
      boxShadow: '-1px 1px 14px -5px black'
    }}>
      <img src={folderImg} height='28px' width='35px'/>
    </Box>
      {/* <ModalForm className='edit-form-container-modal' state={{open: recipeView, setOpen: setRecipeView}}>
        <EditForm recipe={recipe}/>
      </ModalForm> */}
    <Box sx={{ height: "194px", width: '100%', background: 'black' }}>
      <CardMedia
        id={`card-media-recipe-${recipe._id}`}
        component="img"
        height="194"
        sx={{width: '-webkit-fill-available'}}
        image={imgSrc}
        alt="Paella dish"
      />
    </Box>
    <Box
      name={`container-${recipe._id}`}
      onMouseEnter={e => {handleMouseEnter(e)}}
      onMouseLeave={e => handleMouseLeave(e)}
      sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        padding: '5%',
        height: '-webkit-fill-available',
        cursor: 'pointer'
    }}>
      <Box className='select-text-hidden' id={`text-container-${recipe._id}`}>
        <Grid container>
          <Grid item xs={8} cursor='pointer'>
            <CardHeader
              sx={{
                width: '100%',
                color: 'white !important',
                padding: 0
              }}
              title={recipe.name}
            />
          </Grid>
          <Grid sx={{textAlign: 'end'}} item xs={4}>{check}</Grid>
        </Grid>
        <Typography cursor='pointer' color={'white'} variant='h7'>{d}</Typography>
        <CardActions
          sx={{
              justifyContent: 'end',
              position: 'absolute',
              bottom: '0px',
              right: '0px'
          }} disableSpacing>
          {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon 
              onClick={handleLikeFavs}
              sx={{color: actions ? state ? state[`fav_${recipe._id}`] ? 'red' : 'white' : 'white' : 'white'}}
            />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon sx={{color: 'white'}}/>
          </IconButton>
          <IconButton onClick={()=>deleteRecipe(recipe._id)} aria-label="share">
            <DeleteIcon sx={{color: 'white'}}/>
          </IconButton> */}
        </CardActions>
      </Box>
    </Box>
  </Card>);
}
