// AUTHENTIFICATION
export const LOGIN_EXITOSO          =           'LOGIN_EXITOSO';
export const LOGIN_ERROR            =             'LOGIN_ERROR';
export const REGISTRO_EXITOSO       =        'REGISTRO_EXITOSO';
export const REGISTRO_ERROR         =          'REGISTRO_ERROR';
export const OBTENER_USUARIO        =         'OBTENER_USUARIO';
export const CERRAR_SESION          =           'CERRAR_SESION';
export const MODIFICAR_USUARIO      =       'MODIFICAR_USUARIO';

// ALERTS
export const MOSTRAR_ALERTA         =          'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA         =          'OCULTAR_ALERTA';

// SECTIONS
export const MOSTRAR_SECCIONES      =       'MOSTRAR_SECCIONES';

// MODULES
export const MOSTRAR_MODULOS        =         'MOSTRAR_MODULOS';
export const CREAR_MODULO           =            'CREAR_MODULO';
export const SELECCIONAR_MODULO     =      'SELECCIONAR_MODULO';

// RECETAS
export const MOSTRAR_RECETAS        =         'MOSTRAR_RECETAS';
export const GUARDAR_RECETA         =          'GUARDAR_RECETA';
export const ELIMINAR_RECETA        =         'ELIMINAR_RECETA';
export const MODIFICAR_RECETA       =        'MODIFICAR_RECETA';

// INGREDIENTES
export const MOSTRAR_INGREDIENTES   =    'MOSTRAR_INGREDIENTES';

// FOLDERS
export const CREAR_FOLDER           =            'CREAR_FOLDER';
export const OBTENER_FOLDERS        =         'OBTENER_FOLDERS';


