import React, { useContext, useEffect, useState } from 'react';
import Menu from '../material/Menu';
import ModuleContext from '../../context/modules/moduleContext';
import { Box, Typography } from '@mui/material';
import Recommendation from './Recommendation';
import Videos from './Videos';

export const Content = ({route = false}) => {
  const moduleContext = useContext(ModuleContext);
  const { moduloSeleccionado, modulos } = moduleContext;

  return(<Menu sx={{padding: '0px !important'}}>
    {route === "recommendation"? (<Box sx={{padding: '24px'}}><Recommendation/></Box>): 
    route === "videos" ? (<Box sx={{padding: '24px'}}><Videos/></Box>): (<Default />)}
  </Menu>)
}

const Default = () => (<Box>
  <Typography>Dashboard</Typography>
</Box>)
export default Content;
