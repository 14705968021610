import React, {useState, useEffect} from 'react'
import { 
    Box, Grid, Typography, Button, MenuItem,
    TextField, Switch, Stack, Chip
} from '@mui/material';
import { MediaCard as CardForm, Icons, Slider, BasicModal as ModalForm, BtnImg } from "../../material";
import { SteppersSpecial } from '../elements';
import TableIngredients from '../elements/TableIngredients';

const Create = ({sx = {width: '100%', height: '80vh'}, save = ()=>console.log("save"), refresh=false, action = e => console.log('New Recipe' + e)}) => {
    let stepFilter = {};
    let paramsActives = [];

    const currencies = [
        { value: 'piezas', label: 'pza' },
        { value: 'kilogramos', label: 'kg' },
        { value: 'gramos', label: 'g' },
        { value: 'litros', label: 'L' },
        { value: 'mililitros', label: 'ml' },
        { value: 'cucharadas', label: 'cdas' },
        { value: 'taza', label: 'cup' },
        { value: 'galon', label: 'gal' },
        { value: 'onzas', label: 'oz' },
        { value: 'libras', label: 'lb' }
    ];

    let typesRecipes = [
        'Carnes', 'Aves', 'Pescado', 'Panaderia', 
        'Huevo', 'Guarniciones', 'Grill', 'Reheat'
    ];
    
    const [process, setProcess] = useState({});
    const [mode, setMode] = useState(1);
    const [activeStep, setActiveStep] = useState(100);
    const [steps, setSteps] = useState([]);
    const [params, setParams] = useState({});
    const [ingredients, setIngredients] = useState({unity:'kilogramos'});
    const onChange = async e =>{ setData({ ...data, [e.target.name]: e.target.value })}
    const [data, setData] = useState({ wordKey: '', typeRecipe: 0 })
    const [wordsKeys, setWord] = useState([])
    const { wordKey } = data;
    
    useEffect(()=>{ console.log(params) },[params])
        
    let parameters = [
        {name: "Nivel de humedad", icon: 'Humedad', color: '#7bcaef'},
        {name: "Ajuste de tiempo", icon: 'Reloj', color: '#8e9092'},
        {name: "Nivel de temperatura", icon: 'Termometro', color: '#db5a1d'},
        {
            name: "Nivel de ventilación",
            icon: 'Ventilador',
            color: 'linear-gradient(to right, #70c09d, #c4fde4)',
            colorLetter: '#70c09d',
            type: 'defined'
        },
    ]

    const handleDelete = num => {
        let dataFiltered = wordsKeys.filter((word, i) => i !== num)
        setWord(dataFiltered)
    }
    
    const handleAdd = e => {
        if(e.key==="Enter"){
            if(e.target.value){
                setWord([...wordsKeys,e.target.value])
                setData({...data, wordKey: ''})
            }
        }
    }

    const changeParams = async e => {
        if(e.target.value){
            if(await e.target.name === 'mode'){
                await setParams({...params, [e.target.name]: parseInt(e.target.value)})
                return;
            }        
            setParams({...params, [e.target.name]: e.target.value})
        }
    }

    useEffect(()=>{
        if(activeStep !== 100){
            stepFilter = steps
            stepFilter[activeStep] = {...steps[activeStep], params}
            setSteps(stepFilter)
            setData({...data, steps})
        }
    },[params])

    useEffect(()=>{
        const fnAsync = async () => {
            if(data.steps && activeStep !== 100){
                paramsActives = await data.steps[activeStep]
                if('params' in paramsActives){
                    setParams(paramsActives.params)
                }else{setParams({})}
            }else{setParams({})}
        }
        fnAsync();
    },[activeStep])

    return(<Box>
        <Grid container>
            <Grid item xs={3}>
                <BtnImg title="Clic aquí para cambiar imagen" sx={{ 
                    height: sx.height,
                    width: sx.width,
                    paddingBottom: '30px'}}
                />
            </Grid>
            <Grid  item xs={9}>
                <Box className='tabScrollStyle' sx={{
                    overflowY: 'scroll',
                    height: sx.height,
                    width: sx.width,
                    padding: '10px'
                }}>
                    <Box>
                        <CardForm>
                            <Box>
                                <Box sx={{marginBottom: '2vh'}}>
                                    <Typography variant='h5' sx={{marginBottom: '10px'}}>Nueva Receta</Typography>
                                    <TextField
                                        sx={{width: '100%', paddingBottom: '25px'}}
                                        id="name"
                                        onChange={e => { setData({ ...data, [e.target.name]: e.target.value})}}
                                        value={data.name}
                                        name="name"
                                        label="Titulo de la receta"
                                        variant="standard"
                                    />
                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
                                            <Box>
                                                <Typography sx={{textAlign: 'left', marginBottom: '20px', fontSize: '12px'}}>Selecciona un tipo de cocción</Typography>
                                                <Grid container spacing={2}>
                                                    {['Conveccion','Combi','Vapor'].map((conv, i) => {
                                                        return(<Grid sx={{textAlign: 'center'}} item xs={4}>
                                                            <Button 
                                                                disabled = {data.steps && activeStep !== 100 ? false : true }
                                                                name='mode'
                                                                onClick={e=>{
                                                                    // setMode(i + 1);
                                                                    setData({...data, convection: i + 1})
                                                                    setParams({...params, mode: i + 1})
                                                                }}
                                                                value={1}
                                                                className={`recipe-btn${params.mode === i + 1 ? '-select' : ''} btn-${data.steps && activeStep !== 100 ? 'avalaible': 'disabled'}`}
                                                            >
                                                                <Icons dimensions={{w: '40', h: '40'}} name={conv}/>
                                                            </Button>
                                                        </Grid>)
                                                    })}
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SteppersSpecial states={{ steps, activeStep, process}}
                                                actions={{setSteps, setActiveStep, setProcess}}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{marginTop: '10px'}} container spacing={5}>
                                        {parameters ? parameters.map(parameter => (<Grid sx={{textAlign: 'center'}} item xs={6}>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Button><Icons dimensions={{w: 30, h: 30}} name={parameter.icon}/></Button>
                                                </Grid>
                                                <Grid sx={{
                                                    display: 'grid',
                                                    alignContent: 'center',
                                                    paddingLeft: '5%'
                                                }} item xs={10}>
                                                    <Typography sx={{textAlign: 'left', fontSize: '0.9rem'}}>{parameter.name}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{paddingX: '10%', marginTop: '15px'}}>
                                                <Slider
                                                    disabled = {activeStep === 100? true : false}
                                                    name={parameter.icon}
                                                    value={params ? params[parameter.icon]: false}
                                                    onChange={changeParams}
                                                    color={parameter.color}
                                                    type={'type' in parameter ? parameter.type : 'standard'}
                                                    colorLetter={'colorLetter' in parameter ? parameter.colorLetter : null}
                                                />
                                            </Box>
                                        </Grid>)): null}
                                    </Grid>
                                </Box>
                            </Box>
                        </CardForm>
                        <CardForm>
                            <Box>
                                <Typography variant='h5' sx={{textAlign: 'center', marginBottom: '20px'}}>Información General</Typography>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <TextField
                                            sx={{width: '100%', paddingBottom: '10px'}}
                                            id="wordKey"
                                            name="wordKey"
                                            label="Filtros"
                                            variant="standard"
                                            onKeyDown={handleAdd}
                                            value={wordKey}
                                            helperText="Presiona enter para añadir filtro"
                                            onChange={onChange}
                                        />
                                        <Stack direction="row" sx={{display: 'contents'}} spacing={1}>
                                            {wordsKeys ? (
                                                wordsKeys.map((word, i) => (
                                                    <Chip
                                                        sx={{marginY: '5px !important'}}
                                                        label={word}
                                                        onDelete={() => handleDelete(i)}
                                                    />
                                                ))
                                            ): null}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container>
                                            <Grid item xs={8} sx={{paddingRight: '20px'}}>
                                                <TextField
                                                    sx={{marginBottom: '20px'}}
                                                    id="outlined-select-currency"
                                                    name='saucer'
                                                    onChange={e=>{
                                                        typesRecipes.map((saucer,i) => {
                                                            if(saucer === e.target.value){
                                                                setData({ ...data, saucer: i})
                                                            }
                                                        })
                                                    }}
                                                    // value={'saucer' in data ? data.saucer : false}
                                                    select
                                                    label="Tipo de receta"
                                                    // defaultValue="Selecciona un platillo"
                                                    variant='standard'
                                                    fullWidth
                                                >
                                                    {typesRecipes.map((option, i) => {
                                                        return(<MenuItem key={i} value={option}>
                                                            <Box sx={{display: 'flex !important' }}>
                                                                <Icons dimensions={{w: '30', h:'30'}} name={option}/>
                                                                <Typography sx={{marginLeft: '20px'}}>{option}</Typography>
                                                            </Box>
                                                        </MenuItem>)
                                                    })}
                                                </TextField>
                                            </Grid>
                                                <Grid item xs={4} sx={{textAlign: 'right'}}>
                                                    <Typography sx={{
                                                    textAlign: 'Left',
                                                    fontSize: '12px',
                                                    color: 'rgba(0, 0, 0, 0.6)'
                                                }}>Aceptar calificación</Typography>
                                                <Switch 
                                                    name='ranking'
                                                    onChange={e=>setData({...data, ranking: e.target.checked})}
                                                    checked={'ranking' in data ? data.ranking : false}
                                                />
                                            </Grid>
                                        </Grid>
                                        <TextField
                                            className="tabScrollStyleNotes"
                                            id="description"
                                            name='description'
                                            onChange={onChange}
                                            value={'description' in data ? data.description : ''}
                                            label="Ingresa una descripción"
                                            sx={{
                                                width: '100%',
                                                marginTop: '10px'
                                            }}
                                            multiline
                                            rows={6}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardForm>
                        <CardForm>
                            <Typography variant='h5' sx={{marginBottom: '40px', textAlign: 'center'}}>Ingredientes</Typography>
                            <Box sx={{margin: 2}}>
                                <Grid container spacing={2}>
                                    <Grid xs={4} sx={{paddingRight: '2.5%'}}>
                                        <TextField
                                            sx={{width: '100%', paddingBottom: '35px'}}
                                            id="nameIngredient"
                                            onChange={e=> setIngredients({
                                                ...ingredients,
                                                [e.target.name]: e.target.value
                                            })}
                                            value={ingredients.nameIndredient}
                                            name="nameIndredient"
                                            label="Nombre"
                                            variant="standard"
                                        />
                                        <Grid sx={{width: '100%', margin: 0}} container>
                                            <Grid xs={5}>
                                                <TextField
                                                    sx={{width: '100%', paddingBottom: '35px'}}
                                                    id="quantity"
                                                    name="quantity"
                                                    type='number'
                                                    minRows={0}
                                                    label="Cantidad"
                                                    value={ingredients.quantity}
                                                    onChange={e => setIngredients({
                                                        ...ingredients,
                                                        [e.target.name]: e.target.value
                                                    })}
                                                    variant="standard"
                                                />
                                            </Grid>
                                            <Grid xs={2}></Grid>
                                            <Grid xs={5}>
                                                <TextField
                                                    variant='standard'
                                                    value={'unity' in ingredients ? ingredients.unity: 'kilogramos'}
                                                    onChange={e => {
                                                        setIngredients({
                                                        ...ingredients,
                                                        unity: e.target.value
                                                    })}}
                                                    select
                                                    label="Unidad"
                                                    helperText="Seleccionar Unidad"
                                                >
                                                    {currencies.map((option, i) => {
                                                        return(
                                                        <MenuItem key={`${option.value}-${i}`} value={option.value}>
                                                        {option.label}
                                                        </MenuItem>
                                                    )})}
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                        <Box>
                                            <Typography
                                                onClick={()=>{
                                                    if('nameIndredient' in ingredients && 'quantity' in ingredients && 'unity' in ingredients){
                                                        setData({
                                                            ...data,
                                                            ingredients: 'ingredients' in data ? [...data.ingredients, ingredients]: [ingredients]
                                                        })
                                                    }
                                                }}
                                                sx={{
                                                    border: '1.5px solid #d5d5d5',
                                                    color: '#867f7f',
                                                    margin: '0px 25%',
                                                    textAlign: 'center',
                                                    borderRadius: '5px',
                                                    padding: '10px 0px',
                                                    cursor: 'pointer',
                                                    '&:hover':{
                                                        color: '#5a5a5a',
                                                        border: '1.5px solid #5a5a5a',
                                                        boxShadow: '0px 0px 6px -1px #a1a1a1'
                                                    }
                                            }}>+ Añadir</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={8}>
                                        <Grid container>
                                            <Grid item xs={10}>
                                                <Typography sx={{marginBottom: '10px'}}>Listado</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {'ingredients' in data && data.ingredients.length > 0 ? data.ingredients.length: null}
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ paddingLeft: '2.5%', height: '35vh'}}>
                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TableIngredients ingredients={data.ingredients} deleteIngredient={index=>{
                                                            if('nameIndredient' in ingredients && 'quantity' in ingredients && 'unity' in ingredients){
                                                                let filtering = 'ingredients' in data ? data.ingredients: [];
                                                                let deletes = filtering.filter((ingredient, i)=>i!==index)
                                                                setData({ ...data, ingredients: deletes })
                                                            }
                                                        }}/>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardForm>
                        <CardForm>
                            <Typography>Notas</Typography>
                            <TextField
                                className="tabScrollStyleNotes"
                                id="notes"
                                name='notes'
                                onChange={onChange}
                                value={'notes' in data ? data.notes : ''}
                                label="Escribe tus notas aquí"
                                sx={{
                                    width: '100%',
                                    marginTop: '40px'
                                }}
                                multiline
                                rows={6}
                            />
                        </CardForm>
                    </Box>
                    <Box sx={{position: 'sticky', bottom: '0px', textAlign:'end'}}>
                        <Button 
                            onClick={async ()=>{
                                if(data.name){
                                    console.log(data)
                                    setData({...data, steps})
                                    await save(data)
                                    action(false)
                                    if(refresh){window.location.reload()}
                                }
                            }}
                            sx={{
                                background:'#445056',
                                '&:hover':{ background:'#d8b915'}
                            }}
                            variant="contained"
                            endIcon={<Icons name='Save'
                        />}>Guardar</Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Box>)
}

export default Create;