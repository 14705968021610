import {Grid, Typography} from '@mui/material';    
import React, { useContext, useEffect } from 'react';
import Menu from '../material/Menu';
import CardOption from './CardOption';
import Carousel from './Carousel';
import option1 from '../../img/banner-chef.jpg'
import option2 from '../../img/banner-recetas.jpg'
import option3 from '../../img/banner-product.png'
import AuthContext from '../../context/auth/authContext';

const Dashboard = () => {
  const authContext = useContext(AuthContext)
  const { autenticado, usuario, usuarioAutenticado } = authContext;

  useEffect(()=>{ usuarioAutenticado() },[])

  return (<Menu sx={{padding: '0px'}}>
    <Carousel/>
    {autenticado ? (
      <Typography
        sx={{
          padding: '5vh 0px 0px 5vw',
          display: 'flex',
          alignItems: 'baseline',
          boxShadow: '0px 3px 8px -6px black'
        }}
      >Hola, <Typography sx={{
        paddingLeft: '1vw',
        fontFamily: "'Lobster'",
        textShadow: '0px 0px 5px #000000',
        color: 'white'
      }} variant='h4'>
        {usuario ? `${usuario.firstName} ${usuario.lastName}`: null}
      </Typography></Typography>): null
    }
    
    <Grid sx={{marginTop: '40px'}} container spacing={2}>
      <Grid item xs={4}>
        <CardOption url={option1} label='Recetas más populares'/>
      </Grid>
      <Grid item xs={4}>
        <CardOption url={option2} label='Videos de recetas por cheffs'/>
      </Grid>
      <Grid item xs={4}>
        <CardOption url={option3} label='Nuestros Productos'/>
      </Grid>
    </Grid>  
  </Menu>);
}

export default Dashboard;