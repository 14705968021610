import React, {useReducer} from 'react'
import moduleReducer from './moduleReducer'
import ModuleContext from './moduleContext'
import clientAxios  from '../../config/axios'

import {
    CREAR_MODULO,
    MOSTRAR_MODULOS,
    SELECCIONAR_MODULO
} from '../../types'

const ModuleState = props => {
    const initialState = { 
        modules: null,
        moduloSeleccionado: ''
    }

    const [ state, dispatch ] = useReducer(moduleReducer, initialState)

    const obtenerModulos = async () => {
        try{
            const result = await clientAxios.get('/api/module')
            dispatch({
                type: MOSTRAR_MODULOS,
                payload: result.data.data
            })
        }catch(err){
            window.location.href = "/";
            console.log(err)
        }
    }

    const crearModulo = async params => {
        try{
            const result = await clientAxios.post('/api/module', params);
            dispatch({
                type: CREAR_MODULO,
                payload: result.data.msg
            })
            if(result.data.success){ return true }
        } catch(err){ console.log(err) }
    }

    const seleccionarModulo = async id => {
        await dispatch({
            type: SELECCIONAR_MODULO,
            payload: id
        })
    }

    return(
        <ModuleContext.Provider
            value={{
                modulos: state.modulos,
                moduloSeleccionado: state.moduloSeleccionado,
                seleccionarModulo,
                obtenerModulos,
                crearModulo
            }}
        >
            {props.children}
        </ModuleContext.Provider>
    )
}

export default ModuleState;
