import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Icons } from '../material';



const Groups = () => {
    const [select, setSelect] = useState(false)
    const [groups, setGroups] = useState([
        {
            name: 'Restaurante 1',
            machines: "maquina 1",
            address: 'Blvd. Rodrigo Gomez #215',
            city: 'N.L.',
            country: 'Monterrey',
            contactId: '1',
            createAt: '11/05/2023',
            isDeleted: false,
        },
        {
            name: 'Restaurante 2',
            machines: "maquina 2",
            address: 'C. Adolfo Lopez Mateos #1313',
            city: 'N.L.',
            country: 'Monterrey',
            contactId: '2',
            createAt: '11/05/2023',
            isDeleted: false,
        },
        {
            name: 'Restaurante 3',
            machines: ['maquina 3'],
            address: 'Av. Gonzalitoz #2013',
            city: 'N.L.',
            country: 'Monterrey',
            contactId: '3',
            createAt: '11/05/2023',
            isDeleted: false,
        }
    ])

    const [display, setDisplay] = useState("Default")
    return (<Grid container spacing={4}>
        <Grid item xs={4.5}>
            <Box sx={{
                boxShadow: '0px 3px 7px -4px gray',
                minHeight: '50vh',
                background: 'white',
                padding: '10% 15%',
                borderRadius: '15px',
                textAlign: 'center',
                display: 'grid',
                alignContent: 'center'
            }}>
                {display === 'edit' ? (<EditUser/>) :(<Box sx={{ display: 'grid', alignContent: 'center'}}>
                    <Typography sx={{color:'#b3b3b3'}}>Selecciona un usuario para editarlo</Typography>
                    <Typography sx={{color:'#b3b3b3'}}>o</Typography>
                    <Typography sx={{
                        color: '#2196f3',
                        cursor: 'pointer'
                    }} onClick={()=>setDisplay("edit")}>+ agrega un usuario nuevo</Typography>
                </Box>)}
            </Box>
        </Grid>
        <Grid item xs={7.5}>
            <TableContainer component={Paper} className='tabScrollStyle'>
                <Table sx={{ wiidth: '100%' }} size="small" aria-label="a dense table">
                <TableHead sx={{backgroundColor: '#445056'}}>
                    <TableRow sx={{'& th':{color: 'white !important'}}}>
                        <TableCell>Nombre</TableCell>
                        <TableCell align="right">Máquinas</TableCell>
                        <TableCell align="right">Dirección</TableCell>
                        <TableCell align="right">Creado por</TableCell>
                        <TableCell align="right">Deshabilitado</TableCell>
                        <TableCell align="right">Fecha de creación</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {groups.map((group, i) => (<TableRow
                        onClick={()=>setSelect(i)}
                        key={group.name}
                        sx={{
                            cursor: 'pointer',
                            background: select === i ? '#e7e7e7': 'transparent',
                            '&:last-child td, &:last-child th': { border: 0 }
                    }}>
                        <TableCell component="th" scope="row">{`${group.name}`}</TableCell>
                        <TableCell align="right">{group.machines}</TableCell>
                        <TableCell align="right">{`${group.address}, ${group.country}, ${group.city}`}</TableCell>
                        <TableCell align="right">{group.contactId}</TableCell>
                        <TableCell align="right">{`${group.isDeleted}`}</TableCell>
                        <TableCell align="right">{group.createAt}</TableCell>
                    </TableRow>))}
                </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>);
}

const EditUser = () => {
    return(<Box>
        <Grid container spacing={2}>
            <Grid item xs={6} sx={{padding: '0px !important', paddingRight: '2.5% !important'}}>
                <TextField
                    sx={{width: '100%', paddingBottom: '25px'}}
                    id="name"
                    name="name"
                    label="Nombre"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={6} sx={{padding: '0px !important', paddingLeft: '2.5% !important'}}>
                <TextField
                    sx={{width: '100%', paddingBottom: '25px'}}
                    id="lastname"
                    name="lastname"
                    label="Apellido"
                    variant="standard"
                />
            </Grid>
            <TextField
                sx={{width: '100%', paddingBottom: '25px'}}
                id="email"
                name="email"
                label="Correo electrónico"
                variant="standard"
            />
            <TextField
                sx={{width: '100%', paddingBottom: '25px'}}
                id="password"
                name="password"
                label="Contraseña"
                variant="standard"
            />
            <TextField
                sx={{width: '100%', paddingBottom: '25px'}}
                id="password"
                name="password"
                label="Repetir Contraseña"
                variant="standard"
            />
        </Grid>
        <Button 
            // onClick={async ()=>{
            //     if(data.name){
            //         console.log(data)
            //         setData({...data, steps})
            //         await save(data)
            //         action(false)
            //         if(refresh){window.location.reload()}
            //     }
            // }}
            sx={{
                background:'#445056',
                '&:hover':{ background:'#d8b915'}
            }}
            variant="contained"
            endIcon={<Icons name='Save'
        />}>Guardar</Button>
    </Box>)
}

export default Groups;