import img1 from '../../../img/icons/ml-icons/1-available-block.png';
import img1D from '../../../img/icons/ml-icons/1-disabled-block.png';
import img2 from '../../../img/icons/ml-icons/2-available-block.png';
import img2D from '../../../img/icons/ml-icons/2-disabled-block.png';
import img3 from '../../../img/icons/ml-icons/3-available-block.png';
import img3D from '../../../img/icons/ml-icons/3-disabled-block.png';
import img4 from '../../../img/icons/ml-icons/4-available-block.png';
import img4D from '../../../img/icons/ml-icons/4-disabled-block.png';
import img5 from '../../../img/icons/ml-icons/5-available-block.png';
import img5D from '../../../img/icons/ml-icons/5-disabled-block.png';
import img6 from '../../../img/icons/ml-icons/6-available-block.png';
import img6D from '../../../img/icons/ml-icons/6-disabled-block.png';
import img7 from '../../../img/icons/ml-icons/7-available-block.png';
import img7D from '../../../img/icons/ml-icons/7-disabled-block.png';

const ImagesStep = ({number = 0, disabled = false, height='60px'}) => {
    switch(number){
        case 1:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '5px 0px 0px 5px'
                }} 
                src={disabled ? img1: img1D}
            />)
        case 2:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '5px 0px 0px 5px'
                }}
                src={disabled ? img2: img2D}
            />)
        case 3:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '5px 0px 0px 5px'
                }}
                src={disabled ? img3: img3D}
            />)
        case 4:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '5px 0px 0px 5px'
                }}
                src={disabled ? img4: img4D}
            />)
        case 5:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '5px 0px 0px 5px'
                }}
                src={disabled ? img5: img5D}
            />)
        case 6:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '5px 0px 0px 5px'
                }}
                src={disabled ? img6: img6D}
            />)
        case 7:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '6px 0px 0px 6px !important'
                }}
                src={disabled ? img7: img7D}
            />)
        default:
            return(<img
                style={{
                    maxHeight: height,
                    borderRadius: '5px 0px 0px 5px'
                }}
                src={disabled ? img1: img1D}
            />)
    }
}

export default ImagesStep;