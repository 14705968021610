import { MOSTRAR_INGREDIENTES } from '../../types'

export default( state, action) => {
    switch(action.type){
        case MOSTRAR_INGREDIENTES:
            return { ingredientes: action.payload };
        default:
            return state;
    }
}
