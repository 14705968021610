import {
    ArrowForwardIos, ArrowBackIos, BreakfastDiningRounded,
    MoveToInbox as InboxIcon, Mail as MailIcon, Dashboard,
    Menu as MenuIcon, FileCopyOutlined as FileCopyIcon,
    Save as SaveIcon, Print as PrintIcon, Share as ShareIcon,
    PanToolOutlined as PanToolOutlinedIcon, DesktopWindows,
    AppRegistration as AppRegistrationIcon, AccountBox,
    BookmarkAddOutlined as BookmarkAddOutlinedIcon,
    DifferenceOutlined as DifferenceOutlinedIcon,
    NoteAddOutlined as NoteAddOutlinedIcon,
    Groups as GroupsIcon, DeleteSharp as DeleteSharpIcon, 
    ModeEditRounded as ModeEditRoundedIcon, People,
    AdminPanelSettings as AdminPanelSettingsIcon,
    RecentActors as RecentActorsIcon,
    Reviews,
    Microwave as MicrowaveIcon, ArrowBack as ArrowBackIcon,
    OndemandVideo, SdCardAlertOutlined, GavelOutlined
} from '@mui/icons-material';
import {IMAGE} from '../../img/icons';
import { ImageIcon } from './';

const Icons = ({name, sx, dimensions={w: '20', h: '20'}}) => {
    switch(name){
        case 'ChevronLeft':             return(<ArrowForwardIos sx={sx}/>)
        case 'Back':                    return(<ArrowBackIcon sx={sx}/>)
        case 'Users':                   return(<People sx={sx}/>)
        case 'Teams':                   return(<GroupsIcon sx={sx}/>)
        case 'UsersManagement':         return(<RecentActorsIcon sx={sx}/>)
        case 'Roles':                   return(<AdminPanelSettingsIcon sx={sx}/>)
        case 'Machines':                return(<MicrowaveIcon sx={sx}/>)
        case 'EditSettingsIcon':        return(<AppRegistrationIcon sx={sx}/>)
        case 'ChevronRight':            return(<ArrowBackIos sx={sx}/>)
        case 'Equipo ':                 return(<DesktopWindows sx={sx}/>)
        case 'Inbox':                   return(<InboxIcon sx={sx}/>)
        case 'Mail':                    return(<MailIcon sx={sx}/>)
        case 'Menu':                    return(<MenuIcon sx={sx}/>)
        case 'Recipe':                  return(<BreakfastDiningRounded sx={sx}/>)
        case 'Dashboard':               return(<Dashboard sx={sx}/>)
        case 'FileCopy':                return(<FileCopyIcon sx={sx}/>)
        case 'Print':                   return(<PrintIcon sx={sx}/>)
        case 'Share':                   return(<ShareIcon sx={sx}/>)
        case 'AddRecipe':               return(<NoteAddOutlinedIcon sx={sx}/>)
        case 'AddRecipeML':             return(<BookmarkAddOutlinedIcon sx={sx}/>)
        case 'AddRecipeSimp':           return(<DifferenceOutlinedIcon sx={sx}/>)
        case 'edit':                    return(<ModeEditRoundedIcon sx={sx}/>)
        case 'Accion':                  return(<PanToolOutlinedIcon sx={sx}/>)
        case 'Mi-Cuenta':               return(<AccountBox sx={sx}/>);
        case 'recomendacion':           return(<Reviews sx={sx}/>);
        case 'cuenta':                  return(<AccountBox sx={sx}/>);
        case 'videos':                  return(<OndemandVideo sx={sx}/>);
        case 'aviso-legal':             return(<SdCardAlertOutlined sx={sx}/>);
        case 'terminos':                return(<GavelOutlined sx={sx}/>);
        case 'Thickness':               return(ImageIcon(IMAGE.thickness,                 dimensions));
        case 'TempOven':                return(ImageIcon(IMAGE.reloj,                     dimensions));
        case 'Delta':                   return(ImageIcon(IMAGE.delta,                     dimensions));
        case 'Gratin':                  return(ImageIcon(IMAGE.gratin,                    dimensions));
        case 'CoreProbe':               return(ImageIcon(IMAGE.coreprobe,                 dimensions));
        case 'CookTime':                return(ImageIcon(IMAGE.cookTime,                  dimensions));
        case 'Browning':                return(ImageIcon(IMAGE.browning,                  dimensions));
        case 'folder-recipe':           return(ImageIcon(IMAGE.fRecipe,                   dimensions));
        case 'folder':                  return(ImageIcon(IMAGE.fMl,                       dimensions));
        case 'trash':                   return(ImageIcon(IMAGE.trash,                     dimensions));
        case 'Save':                    return(ImageIcon(IMAGE.save,                      dimensions));
        case 'Conveccion':              return(ImageIcon(IMAGE.convection,                dimensions));
        case 'SmartRecipe':             return(ImageIcon(IMAGE.smartRecipe,               dimensions));
        case 'SmartRecipeDisabled':     return(ImageIcon(IMAGE.smartRecipeDisabled,       dimensions));
        case 'Combi':                   return(ImageIcon(IMAGE.combi,                     dimensions));
        case 'Vapor':                   return(ImageIcon(IMAGE.steam,                     dimensions));
        case 'Ventilador':              return(ImageIcon(IMAGE.ventilador,                dimensions));
        case 'Coccion':                 return(ImageIcon(IMAGE.coccion,                   dimensions));
        case 'Precalentar':             return(ImageIcon(IMAGE.precalentar,               dimensions));
        case 'Sonda':                   return(ImageIcon(IMAGE.sonda,                     dimensions));
        case 'Humedad':                 return(ImageIcon(IMAGE.humedad,                   dimensions));
        case 'Book':                    return(ImageIcon(IMAGE.recipes,                   dimensions));
        case 'newRecipe':               return(ImageIcon(IMAGE.imgNewRecipeW,             dimensions));
        case 'newRecipeDisabled':       return(ImageIcon(IMAGE.imgNewRecipeDisabled,      dimensions));
        case 'newRecipeML':             return(ImageIcon(IMAGE.imgSrcNewRecipeMLW,        dimensions));
        case 'newRecipeMLDisabled':     return(ImageIcon(IMAGE.imgSrcNewRecipeMLDisabled, dimensions));
        case 'Carnes':                  return(ImageIcon(IMAGE.meat,                      dimensions));
        case 'Aves':                    return(ImageIcon(IMAGE.chiken,                    dimensions));
        case 'Pescado':                 return(ImageIcon(IMAGE.fish,                      dimensions));
        case 'Panaderia':               return(ImageIcon(IMAGE.bakery,                    dimensions));
        case 'Huevo':                   return(ImageIcon(IMAGE.eggs,                      dimensions));
        case 'Guarniciones':            return(ImageIcon(IMAGE.vegetables,                dimensions));
        case 'RecipeBook':              return(ImageIcon(IMAGE.recipes,                   dimensions));
        case 'RecipeBookDisabled':      return(ImageIcon(IMAGE.bookRecipeDisabled,        dimensions));
        case 'Grill':                   return(ImageIcon(IMAGE.grill,                     dimensions));
        case 'Reheat':                  return(ImageIcon(IMAGE.reheat,                    dimensions));
        case 'LogoYellow':              return(ImageIcon(IMAGE.logoYellow,                dimensions));
        case 'LogoBlack':               return(ImageIcon(IMAGE.logoBlack,                 dimensions));
        case 'LogoWhite':               return(ImageIcon(IMAGE.logoWhite,                 dimensions));
        case 'LogoWhiteTrans':          return(ImageIcon(IMAGE.logoWhiteTrans,            dimensions));
        case 'LogoYellowTrans':         return(ImageIcon(IMAGE.logoYellowTrans,           dimensions));
        case 'Facebook':                return(ImageIcon(IMAGE.facebook,                  dimensions));
        case 'Instagram':               return(ImageIcon(IMAGE.instagram,                 dimensions));
        case 'Tiktok':                  return(ImageIcon(IMAGE.tiktok,                    dimensions));
        case 'Termometro':              return(ImageIcon(IMAGE.termometro,                dimensions));        
        case 'Reloj':                   return(ImageIcon(IMAGE.reloj,                     dimensions));
        case 'Precalentamiento':        return(ImageIcon(IMAGE.precalentamiento,          dimensions));
        case 'Cortar':                  return(ImageIcon(IMAGE.cortar,                    dimensions));
        case 'Pincelar':                return(ImageIcon(IMAGE.pincelear,                 dimensions));
        case 'Añadir ingrediente':      return(ImageIcon(IMAGE.agregar,                   dimensions));
        case 'Añadir liquido':          return(ImageIcon(IMAGE.agregarLiquido,            dimensions));
        default: break;
    }
}

export default Icons;