import { Box, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ModuleContext from "../../context/modules/moduleContext";
import CommentIcon from '@mui/icons-material/Comment';

const Recommendation = () => {
  const moduleContext = useContext(ModuleContext);
  const { seleccionarModulo, moduloSeleccionado } = moduleContext;
  const {id} = useParams();
  const [contenido, setContenido] = useState([
    {
        _id: "b00001",
        typeFormat: 1,
        title: "Nuevo modulo añadido",
        subtitle: "Se agregan nuevas funcionalidades.",
        // url: "https://www.youtube-nocookie.com/embed/tNap60mB_lg?controls=0",
        description: "Este video es de prueba",
        comments: [],
        createAt: "24/05/2023"
    },
    // {
    //     _id: "b00002",
    //     typeFormat: 1,
    //     title: "Proceso de mantenimiento",
    //     // url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
    //     description: "Este video es de prueba",
    //     comments: [],
    //     createAt: "24/05/2023"
    // },
    // {
    //     _id: "b00003",
    //     typeFormat: 1,
    //     title: "Receta costillas BBQ",
    //     // url: "https://www.youtube-nocookie.com/embed/daw0Qh_Nf4s?controls=0",
    //     description: "Receta para elaborar tus costillas BBQ en el horno de KookWorks",
    //     comments: [],
    //     createAt: "24/05/2023"
    // },
    // {
    //     _id: "b00004",
    //     typeFormat: 1,
    //     title: "Receta de cocina al vacío",
    //     // url: "https://www.youtube-nocookie.com/embed/2e9C1iCmtVs?controls=0",
    //     description: "Este video es de prueba",
    //     comments: [],
    //     createAt: "24/05/2023"
    // },
    // {
    //     _id: "b00005",
    //     typeFormat: 1,
    //     title: "Proceso de mantenimiento",
    //     // url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
    //     description: "Este video es de prueba",
    //     comments: [],
    //     createAt: "24/05/2023"
    // },
    // {
    //     _id: "b00006",
    //     typeFormat: 1,
    //     title: "Proceso de mantenimiento",
    //     // url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
    //     description: "Este video es de prueba",
    //     comments: [],
    //     createAt: "24/05/2023"
    // },
    // {
    //     _id: "b00007",
    //     typeFormat: 1,
    //     title: "Receta de cocina al vacío",
    //     // url: "https://www.youtube-nocookie.com/embed/tNap60mB_lg?controls=0",
    //     description: "Este video es de prueba",
    //     comments: [],
    //     createAt: "24/05/2023"
    // },
    // {
    //     _id: "b00008",
    //     typeFormat: 1,
    //     title: "Proceso de mantenimiento",
    //     // url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
    //     description: "Este video es de prueba",
    //     comments: [],
    //     createAt: "24/05/2023"
    // },
    // {
    //     _id: "b00009",
    //     typeFormat: 1,
    //     title: "Proceso de mantenimiento",
    //     // url: "https://www.youtube-nocookie.com/embed/B_mMqA__7Xk?controls=0",
    //     description: "Este video es de prueba",
    //     comments: [],
    //     createAt: "24/05/2023"
    // }
  ])


  useEffect(()=>{ seleccionarModulo(id) },[])
  useEffect(()=>{
    if(!moduloSeleccionado){
      if(id) seleccionarModulo(id);
    }
  },[moduloSeleccionado])

  return(<Grid container>
    {contenido ? contenido.map(pub => {
      return(<Grid item xs={12}>
        <Box sx={{
          background: 'white', boxShadow: '0px 10px 15px -15px gray',
          borderRadius: '15px'
        }}>
          <Grid sx={{ padding: '3%'}} container>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h5">{pub.title}</Typography>
              <Typography sx={{fontSize: '13px'}}>{pub.subtitle}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Typography sx={{textAlign: 'end'}}>{pub.description}</Typography>
            </Grid>
          </Grid>
          <Grid container sx={{padding: '15px'}}>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4}><CommentIcon sx={{cursor: 'pointer'}}/></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Typography sx={{ textAlign: 'end', fontSize: '12px' }}>{pub.createAt}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>)
    }): (<Typography>No hay novedades</Typography>)}
  </Grid>)
}

export default Recommendation;