import imgNewRecipeDisabled from '../icons/Add-Manual-disabled.png'
import imgNewRecipeW from '../../img/icons/Add-Manual-white.png';
import imgNewRecipe from '../../img/icons/Add-Manual-8.png';
import imgSrcNewRecipeML from '../../img/icons/Add-ML-8.png';
import imgSrcNewRecipeMLW from '../../img/icons/Add-ML-white.png';
import imgSrcNewRecipeMLDisabled from '../../img/icons/Add-ML-disabled.png';
import recipes from '../../img/icons/Recipes-8.png';
import chiken from '../../img/icons/Chiken-8.png';
import meat from '../../img/icons/Meat-8.png';
import fish from '../../img/icons/Fish-8.png';
import bakery from '../../img/icons/Bakery-8.png';
import eggs from '../../img/icons/eggs-8.png';
import vegetables from '../../img/icons/vegetables-8.png';
import grill from '../../img/icons/Grill-8.png';
import reheat from '../../img/icons/Reheat-8.png';
import convection from '../../img/icons/Convection-8.png';
import steam from '../../img/icons/Steam-8.png';
import combi from '../../img/icons/Combi-8.png';
import ventilador from '../../img/icons/ventilador.png';
import humedad from '../../img/icons/humedad.png';
import coccion from '../../img/icons/coccion-delta.png';
import sonda from '../../img/icons/sonda.png';
import reloj from '../../img/icons/reloj.png';
import termometro from '../../img/icons/termometro.png';
import precalentar from '../../img/icons/Precalentar.png';
import smartRecipe from '../../img/icons/Add-inteligent-white.png';
import smartRecipeDisabled from '../../img/icons/Add-inteligent-disabled.png';
import bookRecipe from '../../img/icons/BookRecipe.png';
import bookRecipeDisabled from '../../img/icons/BookRecipeDisabled.png';
import logoYellow from '../../img/icons/Logo_KW_L_yellow-8.png';
import logoBlack from '../../img/icons/Logo_KW_L_Black-8.png';
import logoWhite from '../../img/icons/Logo_KW_L_white-8.png';
import logoWhiteTrans from '../../img/icons/Recurso 32-8.png';
import logoYellowTrans from '../../img/icons/logo-trans-yellow.png';
import facebook from '../../img/icons/redes-facebook.png';
import instagram from '../../img/icons/redes-instagram.png';
import tiktok from '../../img/icons/redes-tiktok.png';
import precalentamiento from '../../img/icons/Precalentar.png';
import cortar from '../icons/Cut-available.png';
import pincelear from '../icons/pincelear.png';
import agregar from '../icons/add-b.png';
import agregarLiquido from '../icons/addliquid-b.png';
import save from '../icons/Save.png';
import trash from '../icons/trash.png';
import fIntelligent from '../icons/folder-intelligent.png';
import fMultilevel from '../icons/folder-multilevel.png';
import fMl from '../icons/folder-ml.png';
import fRecipe from '../icons/folder-recipe.png';
import folder from '../icons/folder.png';
import thickness from '../icons/thickness.png';
import browning from '../icons/Browning.png';
import cookTime from '../icons/CookTime.png';
import coreprobe from '../icons/CoreProbe.png';
import gratin from '../icons/Gratin.png';
import delta from '../icons/Delta.png'

export const IMAGE = {
    imgSrcNewRecipeML: imgSrcNewRecipeML, imgNewRecipe, recipes,
    chiken, meat, fish, bakery, eggs, vegetables, grill, logoBlack,
    reheat, convection, steam, combi, ventilador, humedad, logoWhite,
    coccion, sonda, precalentar, smartRecipe, bookRecipe, logoYellow,
    facebook, instagram, tiktok, termometro, reloj, logoWhiteTrans, logoYellowTrans,
    imgSrcNewRecipeMLW, imgNewRecipeW, precalentamiento, cortar, pincelear,
    agregar, agregarLiquido, save, trash, fIntelligent, fMultilevel, fRecipe, 
    folder, thickness, browning, cookTime, coreprobe, gratin, delta, fMl,
    imgNewRecipeDisabled, imgSrcNewRecipeMLDisabled, smartRecipeDisabled,
    bookRecipeDisabled
}