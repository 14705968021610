import React, {useContext, useEffect, useState} from 'react';
import { Box, Grid, styled, Button ,TextField, Typography, Checkbox, 
  FormControlLabel, MenuItem, Breadcrumbs, Link } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FolderForm, Loading, NewRecipe, RecipeForm } from './elements';
import DragAndDrop from './elements/DragNdrop';
import {
  BasicModal as ModalForm, MenuComponent as Menu,
  Icons, SplitComponent as SplitButton
} from '../material';
import RecipeContext from '../../context/recipes/recipeContext';
import AuthContext from '../../context/auth/authContext';
import FolderContext from '../../context/folders/folderContext';
import ModuleContext from '../../context/modules/moduleContext';
import IngredientContext from '../../context/ingredients/ingredientContext';
import '../../styles/recipes.css'
import Smart from './forms/Smart';
import SearchField from '../material/SearchField';

export const RecipesManagement = () => {
  const moduleContext = useContext(ModuleContext);
  const { moduloSeleccionado, modulos } = moduleContext;
  const [modulo, setModulo] = useState(false)

  useEffect(()=>{
    if(moduloSeleccionado){ setModulo(modulos.filter(m => m._id === moduloSeleccionado)) }
  },[moduloSeleccionado])

  return(<Menu route='Gestión de recetas' sx={{padding: '0px !important'}}>
    <Box><Recipes/></Box>
    {/* {modulo ? 
      modulo[0].route === '/recipes' ? (<Box><Recipes/></Box>):
      modulo[0].route === '/recipes/simples' ? (<SimpleRecipes/>):
      modulo[0].route === '/recipes/ml' ? (<Box sx={{padding: '24px'}}><RecipesMl/></Box>): 
      modulo[0].route === '/recipes/smart' ? (<SmartRecipes/>): (<Recipes/>) : (<Recipes/>)} */}
  </Menu>)
}

export const Recipes = () => {
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const folderContext = useContext(FolderContext);
  const { folders, obtenerFolders } = folderContext;

  const recipeContext = useContext(RecipeContext);
  const { guardarReceta, obtenerRecetas, eliminarReceta, recetas } = recipeContext;
  const ingredientContext = useContext(IngredientContext);
  const { obtenerIngredientes } = ingredientContext;
  const [modalView, setModalView] = useState(0);
  const [multinivel, setMultinivel] = useState(0);
  const [favs, setFavs] = useState({});
  const [anchorEl, setAnchorEl] = useState(false);
  const [folderView, setFolderView] = useState(false);
  const [checked, setChecked] = useState({all: false});
  const [filtradas, setFiltradas] = useState([]);
  const [route, setRoute] = useState("recetas");
  const [selectFolder, setSelectFolder] = useState([]);
  const [folderName, setFolderName] = useState("default");

  const handleChangeAll = () => { setChecked({all: checked.all ? false : true}) };

  const handleChange = async (e, i) => {
    let recetaFiltrada = recetas.filter((receta, index) => index === i)[0];
    if(e.target.checked){
      setFiltradas([...filtradas, recetaFiltrada]);
    }else{
      if(Object.keys(recetaFiltrada).length !== 0){
        let deleteData = filtradas.filter((receta, index) => receta._id !== recetaFiltrada._id);
        setFiltradas(deleteData)
      }
    }
    setChecked({
      ...checked,
      [e.target.name]: !checked[e.target.name]
    });
  }
  
  useEffect(()=>{
    obtenerRecetas();
    obtenerIngredientes();
    obtenerFolders();
  },[]); 
  
  useEffect(()=>{
    setRecipesFilter(recetas);
    setFolderFilter(folders)
  },[recetas, folders])

  const options = [
    { icon: <Icons name='newRecipe'/>,
      name: 'Crear Receta',
      action: setModalView 
    },
    { icon: <Icons name='newRecipeML'/>,
      name: 'Crear Receta Multinivel',
      action: setMultinivel 
    },
    { icon: <Icons name='AddRecipeSimp'/>,
      name: 'Crear Receta Inteligente'
    }
  ];

  const [filterFolders, setFolderFilter]  = useState(folders);
  const [filterRecipes, setRecipesFilter] = useState(recetas);

  const myFunction = (word) => {
    let words = word.target.value;
    let letters = words.toUpperCase();
    if(letters) {
        var palabrasEnFiltro = letters.split(' ');
        var hallado = 0;
        if(palabrasEnFiltro?.length > 0){
          palabrasEnFiltro?.map(w => {
            setRecipesFilter(recetas.filter(recetaN => recetaN.name.toUpperCase().indexOf(w) !== -1));
            setFolderFilter(folders.filter(folderN => folderN.name.toUpperCase().indexOf(w) !== -1));
          })
        }
    }else{
      setRecipesFilter(recetas)
      setFolderFilter(folders)
    }
  }

  const handleClose = () => { setAnchorEl(false) };
  let state = { open: modalView, setOpen: setModalView };
 
  return (<Box>
    <ModalForm className='new-recipe-container-modal' title='' state={state}>
      <NewRecipe sx={{height: '90vh', width: '100%'}} save={guardarReceta} action={setModalView}/>
    </ModalForm>
    <ModalForm className='new-recipe-container-modal' title='Nueva Receta' state={{open: multinivel, setOpen: setMultinivel}}>
      <RecipesMl/>
    </ModalForm>
    <ModalForm sx={{width: '35%', height: '40vh'}} className='new-recipe-container-modal' title=''
      state={{open: folderView, setOpen: setFolderView}}>
        <Folders recipes={{checked, filtradas}} close={setFolderView} />
    </ModalForm>

    <Box sx={{boxShadow: '0px 0px 20px -5px gray', }}>
      <Box sx={{ background: 'white', padding: '20px' }}>
        <Grid container>
          <Grid sx={{display: 'inline-flex'}} item xs={1.5}>
            <Box className='recipes-options-folders' sx={{ background: 'white'}}>
              <FormControlLabel
                label=""
                control={<Checkbox checked={checked[0] && checked[1]} indeterminate={checked[0] !== checked[1]}
                onChange={handleChangeAll}/>}
              />
              <MoreVertIcon sx={{cursor: 'pointer'}} onClick={()=>{setAnchorEl(anchorEl ? false: true)}}/>
                <Box className='options-folders'
                  sx={{
                    display: anchorEl ? 'block': 'none',
                    boxShadow: '0px 6px 8px -3px black !important',
                  }}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => {
                    handleClose()
                    setFolderView(true)
                  }}>Agregar a Carpeta nueva</MenuItem>
                  <MenuItem onClick={handleClose}>Agregar a Carpeta existente</MenuItem>
                </Box>
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={9}><SearchField callback={myFunction}/></Grid>
              <Grid item xs ={3} sx={{display: 'grid', justifyContent: 'end'}}>
                <SplitButton
                  sx={{alignSelf: 'center', textTransform: 'none'}}
                  color={`var(--top-${usuario?.typeTheme ? usuario?.typeTheme + 1: 1}-color)`}
                  hover={{ 
                    boxShadow: '0px 0px 10px -5px black !important',
                    background: `var(--top-${usuario?.typeTheme ? usuario?.typeTheme + 1: 1}-color)`,
                    opacity: 0.45,
                    color: 'gray'
                  }}
                  options={options}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
    {route === "recetas" ? 
    (<Box className="tabScrollStyle" height='72.5vh' sx={{ overflowY: 'scroll', overflowX: 'hidden'}}>
      <Grid container spacing={2} sx={{padding: '30px 20px'}}>
        {filterFolders ? filterFolders.map((folder, i) => {
          if(!folder.isDeleted){
            return(<Grid key={i} className='recipe-list' item xs={12} sm={6} md={6} lg={4} xl={3}>
            <Box onClick={()=>{
              setRoute("folder");
              setSelectFolder(folder?.recipes)
              setFolderName(folder?.name)
            }}>
              <FolderForm recipe={folder} actions={{state: favs, action: setFavs}}/>
            </Box>
          </Grid>)
          }
        }): null}
        {filterRecipes ? filterRecipes.map((receta, i)=>{
          if(!receta.isDeleted && receta.typeRecipe === 0){
            return(<Grid className='recipe-list' item xs={12} sm={6} md={6} lg={4} xl={3}>
            <RecipeForm check={<FormControlLabel key={i} name={`recipe${i}`} control={
                <Checkbox checked={checked[`recipe${i}`] || checked.all ? true: false} onChange={e=>{
                  handleChange(e, i);
                }}/>}
              />} recipe={receta} actions={{state: favs, action: setFavs}} deleteRecipe={eliminarReceta}/>
          </Grid>)
          }
        }): (<Loading units={9} section='recipes'/>)}
      </Grid>
    </Box>):(<Box className="tabScrollStyle" height='71vh' sx={{ marginLeft: '10px', overflowY: 'scroll', overflowX: 'hidden'}}>
      <Box sx={{ marginTop: '10px', marginLeft: '5px', marginBottom: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            onClick={()=>{setRoute("recetas")}}
            sx={{ cursor:'pointer !important' }}
            underline="hover" color="inherit"
          >Recetas</Link>
          <Typography color="text.primary">{folderName}</Typography>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={4}>
        {selectFolder.length > 0 ? selectFolder?.map((fRecipe, nR)=>{
          let filterRecFol = recetas.filter(rec => rec._id === fRecipe._id);
          if(filterRecFol.length > 0 && !filterRecFol[0]?.isDeleted){
            return(<Grid className='recipe-list' item xs={12} sm={6} md={6} lg={4} xl={3}>
              <RecipeForm check={<FormControlLabel key={nR} name={`recipe${nR}`} control={
                  <Checkbox checked={checked[`recipe${nR}`] || checked.all ? true: false} onChange={e=>{
                    handleChange(e, nR);
                  }}/>}
                />} recipe={filterRecFol[0]} actions={{state: favs, action: setFavs}} deleteRecipe={eliminarReceta}/>
            </Grid>)}
        }):(<Box>
          <Typography>No hay recetas guardadas</Typography>
        </Box>)}
      </Grid>

    </Box>)}
  </Box>);
}

export const RecipesMl = () => {
  const recipeContext = useContext(RecipeContext);
  const { obtenerRecetas, recetas } = recipeContext;
  const folderContext = useContext(FolderContext);
  const { folders, obtenerFolders } = folderContext;
  const ingredientContext = useContext(IngredientContext);
  const { obtenerIngredientes } = ingredientContext;
  const [select, setSelect] = useState({name: false, key: 0})

  useEffect(()=>{
    obtenerRecetas();
    obtenerIngredientes();
    obtenerFolders();
  },[]);

  useEffect(()=>{ if('name' in select){if(select.name){  }} },[select])
  const [data, setData] = useState([])

  return (<Box>
    <DragAndDrop
      state={data}
      folders={folders}
      actions={{
        select,
        setSelect
      }}
      setAction={setData}
    />
    <Box sx={{position: 'sticky', bottom: '0px', textAlign:'end'}}>
      <Button 
          onClick={async ()=>{}}
          sx={{
              background:'#445056',
              '&:hover':{ background:'#d8b915'}
          }}
          variant="contained"
          endIcon={<Icons name='Save'
      />}>Guardar</Button>
    </Box>
  </Box>);
}

export const SimpleRecipes = () => {
  const recipeContext = useContext(RecipeContext);
  const { guardarReceta, obtenerRecetas, recetas } = recipeContext;

  const ingredientContext = useContext(IngredientContext);
  const { obtenerIngredientes } = ingredientContext;

  const [modalView, setModalView] = useState(0);

  useEffect(()=>{
    obtenerRecetas();
    obtenerIngredientes();
  },[]);

  const options = [
    { icon: <Icons name='newRecipeML'   />,  name: 'Crear Receta Multinivel'   },
    { icon: <Icons name='AddRecipeSimp' />,  name: 'Crear Receta Simplificada' },
    { icon: <Icons name='newRecipe'     />,  name: 'Crear Receta', action: setModalView }
  ];

  let state = { open: modalView, setOpen: setModalView };

  return (<Box>
    {/* <Typography className='recipe-title' variant="h4" gutterBottom>Nueva Receta</Typography> */}
    <NewRecipe save={guardarReceta} refresh={true} sx={{height: '89.5vh'}}/>
  </Box>);
}

export const SmartRecipes = Smart

const TextCountChars = styled('p')({
  textAlign: 'end',
  color: '#aeaeae',
  paddingRight: '1vw'
});

const Folders = ({recipes=[], close=()=>console.log('close')}) => {
  const folderContext = useContext(FolderContext);
  const { crearFolder } = folderContext;

  const [folder, setFolder] = useState('')

  return(<Box sx={{padding: '20px 50px'}}>
    <Typography sx={{textAlign: 'center', marginBottom: '15px'}} variant='h5'>Carpeta nueva</Typography>
    <TextField
      // className='auth-inputs'
      id="email"
      name="email"
      label="Nombre de la Carpeta"
      variant="standard"
      onChange={e => setFolder(e.target.value)}
      value={folder}
      fullWidth
    />
    <Button
      sx={{marginTop: '40px !important'}}
      className='auth-inputs-button'
      type="submit"
      variant="contained"
      onClick={()=>{
        if('filtradas' in recipes){
          let idsEXtract = []
          recipes.filtradas.map(filtrada => {
            idsEXtract = [...idsEXtract, {
              _id: filtrada._id,
              name: filtrada.name,
              times: {
                timeMax: filtrada.timeMax,
                timeMin: filtrada.timeMin,
                timeTarget: filtrada.timeTarget
              }
            }]
          })

          crearFolder({name: folder, recipes: idsEXtract});
        }
        close(false)
      }}
    >Aceptar</Button>
  </Box>)
}