import React, {
    useEffect,
    useState,
    useContext,
    useRef,
    useLayoutEffect
} from 'react'
import RecipeContext from '../../../context/recipes/recipeContext';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IngredientContext from '../../../context/ingredients/ingredientContext';
import { Box, Grid, MenuItem, TextField, Typography, Slider, Tooltip, Icon, Button } from '@mui/material';
import { Icons } from '../../material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import carne from '../../../img/platillo-carne.jpg';
import aves from '../../../img/platillo-aves.jpg';
import grill from '../../../img/platillo-grill.jpg';
import huevo from '../../../img/platillo-huevo.jpg';
import pan from '../../../img/platillo-pan.jpg';
import pescado from '../../../img/platillo-pescado.jpg';
import recalentado from '../../../img/platillo-recalentado.jpg';
import vegetales from '../../../img/platillo-vegetales-asados.jpg';
import stepsIcon from '../../../img/icons/list-steps.png';
import termometro from '../../../img/icons/termometro.png';
import convection from '../../../img/icons/Convection-8.png';
import combi from '../../../img/icons/Combi-8.png'
import oven from '../../../img/icons/oven-dark.png'
import reloj from '../../../img/icons/reloj.png'
import steam from '../../../img/icons/Steam-8.png'
import Paretto from '../../material/Paretto';

const containerStyle = {
    boxShadow: '0px 3px 7px -4px gray',
    background: 'white',
    borderRadius: '15px',
    textAlign: 'center',
    display: 'grid',
    alignContent: 'center'
}

const marks = [
  { value: 0,   label: '' },
  { value: 25,  label: '' },
  { value: 50,  label: '' },
  { value: 75,  label: '' },
  { value: 100, label: '' },
];

const valuetext = (value) => {
    return `${value}°C`; // retorna el valor en Texto
}

const valueLabelFormat = (value) => {
    return marks.findIndex((mark) => mark.value === value);
}

const DiscreteSliderValues = ({name, data=[], setData = () => console.log('function')}) => {
    let filter = data?.filter(preset => preset.name === name);

    const handleOnClick = async params => { 
        let res = data?.filter(preset =>  preset.name !== name);
        setData([...res, {name: params.name, value: params.value}]);
    }
    
    return (<Box sx={{ width: '100%' }}>
        <PrettoSlider
            name={name}
            aria-label="Restricted values"
            defaultValue={25}
            value={filter[0]?.value}
            valueLabelFormat={valueLabelFormat}
            getAriaValueText={valuetext}
            onChange={e=>handleOnClick(e.target)}
            step={null}
            valueLabelDisplay="auto"
            marks={marks}
        />
    </Box>);
}

const ValueLabelComponent = (props) => {
    const { children, value } = props;
    return (<Tooltip enterTouchDelay={0} placement="top" title={value}>{children}</Tooltip>);
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired
};

const translateTxt = (txt) => {
    switch(txt){
        case 'Thickness':
            return 'Espesor';
        case 'Browning':
            return 'Dorado';
        case 'CookTime':
            return 'Velocidad de cocción';
        case 'CoreProbe':
            return 'Temperatura interna';
        default:
            return txt
    }
}

const PrettoSlider = styled(Slider)({
    color: '#52af77',
    height: 8,
    // background: 'linear-gradient(to left top, blue 49%, transparent 50%)',
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

const SmartRecipes = () => {
    const recipeContext = useContext(RecipeContext);
    const { recetas, guardarReceta, obtenerRecetas } = recipeContext;
    
    const ingredientContext = useContext(IngredientContext);
    const { obtenerIngredientes } = ingredientContext;

    let recetasInt = [];
    const ref = useRef(null);
    const tipoDeRecetas = ['Carnes', 'Aves', 'Pescado', 'Panaderia', 'Huevo', 'Guarniciones', 'Grill', 'Reheat']
    
    const [selectType, setSelect] = useState('Carnes')
    const [filtroPorPlatillo, setFiltro] = useState([]);
    const [presets, setPresets] = useState([]);
    const [recipeSelect, setRecipeSelect] = useState(false)
    const [display, setDisplay] = useState(0)
    const [steps, setSteps] = useState(false)
    const [width, setWidth] = useState(0);

    useEffect(()=>{
        obtenerRecetas();
        obtenerIngredientes();
    },[]);

    useEffect(()=>{ if(filtroPorPlatillo.length > 0){setRecipeSelect(filtroPorPlatillo[0])} },[filtroPorPlatillo])
    useEffect(()=>{if(ref?.current?.clientWidth){setWidth(ref?.current?.clientWidth)}})
    useEffect(()=>{ 
        if(recipeSelect && recipeSelect.steps.length > 0){ setSteps(recipeSelect.steps)}
        if(recipeSelect?.presets?.length > 0){ setPresets(recipeSelect.presets) }
    },[recipeSelect])

    useEffect(()=>{
      recetasInt = recetas.filter(receta => receta.typeRecipe === 1);
      setFiltro(recetasInt.filter(receta=> tipoDeRecetas[receta.saucer] === selectType));
    },[selectType]);

    useLayoutEffect(() => {
        const handleResize = () => setWidth(ref?.current?.clientWidth);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize) }
    }, []);

    const RecipesDisplay = () => (<Grid container>
        <Grid item xs={7} className='tabScrollStyle' sx={{
            overflowY: 'scroll',
            height: '89.5vh'
        }}>
            <Grid container spacing={4} sx={{padding: '20px'}}>
                <Grid item xs={12} md={7}>
                    <Grid 
                        sx={{
                            paddingX: '20px',
                            boxShadow: '0px 3px 7px -4px gray',
                            borderRadius: '15px',
                            maxHeight: '60px',
                            background: 'white'
                        }}
                        container
                    >
                        <Grid item xs={4}>
                            <Box sx={{display: 'grid'}}>
                                <TextField
                                    sx={{
                                        marginBottom: '20px',
                                        padding: '0px !important',
                                        '& .MuiSelect-select':{
                                            padding: '0px !important',
                                            minHeight: '56px !important',
                                            border: '0px solid black !important'
                                        }
                                    }}
                                    variant='standard'
                                    InputProps={{ disableUnderline: true }}
                                    select
                                    value={selectType}
                                    onChange={e=> {
                                        setSelect(e.target.value)
                                        setPresets([])
                                    }}
                                    label=""
                                    fullWidth
                                >
                                    {tipoDeRecetas.length > 0 ? 
                                        tipoDeRecetas.map((recipe, i) => (
                                        <MenuItem value={recipe} key={i} sx={{padding: '0px !important'}}>
                                            <Icons name={recipe} dimensions={{w:'50px', h: '50px'}}/>
                                        </MenuItem>)):
                                    null}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{ 
                                    marginBottom: '20px',
                                    minHeight: '56px !important',
                                    '& .MuiInputBase-root':{ minHeight: '56px !important' }
                                }}
                                variant='standard'
                                InputProps={{ disableUnderline: true}}
                                select
                                value={ recipeSelect._id }
                                // defaultValue={filtroPorPlatillo[0]}
                                onChange={e=>{
                                    let result = recetas.filter(receta => receta._id === e.target.value)
                                    setPresets([]);
                                    setRecipeSelect(result[0]);
                                }}
                                label=""
                                fullWidth
                            >
                                {filtroPorPlatillo?.map((receta, i) =>{
                                    return(<MenuItem value={receta._id} key={i}>{receta.name}</MenuItem>)
                                })}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid 
                        sx={{
                            boxShadow: '0px 3px 7px -4px gray',
                            borderRadius: '15px',
                            background: 'white',
                            cursor: 'pointer'
                        }} 
                        container
                        onClick={()=>{if(recipeSelect){setDisplay(1)}}
                    }>
                        <Grid item xs={5} sx={{
                            height: '10vh',
                            display: 'grid',
                            justifyContent:'center',
                            alignContent: 'center',
                            minHeight: '60px',
                        }}>
                            <Box sx={{
                                height: '42px',
                                width: '40px',
                                backgroundSize: 'cover',
                                backgroundImage: `url('${stepsIcon}')`
                            }}/>
                        </Grid>
                        <Grid item xs={7} sx={{
                            display: 'grid',
                            justifyContent:'start',
                            alignContent: 'center'
                        }}>
                            <Typography>Procedimientos</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{
                boxShadow: '0px 3px 7px -4px gray',
                minHeight: '50vh',
                background: 'white',
                marginX: '20px',
                padding: '7% 10%',
                borderRadius: '15px'
            }}>
                <Box>
                    {recipeSelect ? (<Box sx={{
                        width:'100%',
                        textAlign: 'center',
                        display: 'grid',
                        alignContent: 'center',
                        justifyContent: 'center'
                    }}>
                        {recipeSelect?.presets?.map(preset => {
                            return(<Grid container sx={{height: '16vh'}}>
                                <Grid item xs={4} sx={{height: '16vh'}}>
                                    <Box sx={{
                                        display: 'grid', 
                                        justifyContent:'end'
                                    }}>
                                        <Icons dimensions={{w:'65%', h: '8vh'}} name={preset.name}/>
                                    </Box>
                                </Grid>
                                <Grid item xs={8} sx={{height: '16vh'}}>
                                    <Typography sx={{textAlign: 'left'}}>{translateTxt(preset.name)}</Typography>
                                    <DiscreteSliderValues name={preset.name} data={presets} setData={setPresets}/>
                                </Grid>
                        </Grid>)})}
                    </Box>): (<Box><Typography sx={{textAlign: 'center', color: 'gray'}} variant='h6'>
                        Selecciona un platillo
                    </Typography></Box>)}
                </Box>
            </Box>
            <Grid container sx={{paddingX: '20px'}}>
                <Grid item xs={6}>
                    
                </Grid>
                <Grid item xs={6} sx={{marginTop: '20px', textAlign: 'end'}}>
                    <Button
                        onClick={()=>{
                            let newRecipe = {...recipeSelect, presets};
                            console.log(presets);
                            console.log(recetas);
                        }}
                        sx={{
                            background: '#445056',
                            '&:hover':{ background:'#d8b915'},
                            marginBottom: '30px'
                        }}
                        variant="contained"
                        endIcon={<Icons name='Save'
                    />}>Guardar</Button>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={5}>
            <Box sx={{ background: 'black' }}>
                <Box sx={{
                    backgroundImage: `url('${carne}')`,
                    height: '89.5vh',
                    width: '100%',
                    backgroundSize: 'cover',
                    opacity: 0.7
                }}/>
            </Box>
        </Grid>
    </Grid>)

    return (<Box>
        {display === 0 ? (<RecipesDisplay/>):null}
        {display === 1 ? (
            <Grid item xs={7}>
                <Box sx={{...containerStyle, margin: '40px'}}>
                    <Grid
                        container
                        sx={{
                            background: '#e4e4e4',
                            borderRadius: '20px 20px 0px 0px',
                            boxShadow:'0px 1px 9px -6px black',
                    }}>
                        <Grid xs={2} sx={{ textAlign: 'start'}}>
                            <Button
                                onClick={()=>setDisplay(0)}
                                sx={{
                                    '&:hover': {
                                        background: "transparent !important",
                                        '& .icon-btn-return':{
                                            color: 'black',
                                        }
                                    },
                                    '&:select':{ background: "transparent !important" }
                            }}>
                                <KeyboardBackspaceIcon className='icon-btn-return'
                                    sx={{ fontSize: '30px', cursor: 'pointer', color: '#76767a', marginY: '15px' }}
                                />
                            </Button>
                        </Grid>
                        <Grid xs={8}>
                            <Typography variant='h5' sx={{paddingY: '15px'}}>
                                <b>{recipeSelect.name}:</b> {tipoDeRecetas[recipeSelect.saucer]}
                            </Typography>
                        </Grid>
                        <Grid xs={2}></Grid>
                    </Grid>
                    <Grid container sx={{padding: '50px'}} spacing={4}>
                        <Grid item xs={12} md={5} lg={5} sx={{paddingLeft: '0px !important'}}>
                            <Box sx={{padding: '0px 15%'}}>
                                <Paretto status={30}>
                                    <img src={convection} className='convection-img'/>
                                </Paretto>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7}>
                            <Steps>
                                <Line bg='#434f55' txtColor='white' className='header-table-smart-steps'>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <Typography sx={{fontSize: '12px', textAlign: 'start'}}>Paso</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography sx={{fontSize: '12px', textAlign: 'center'}}>Temperatura</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography sx={{fontSize: '12px', textAlign: 'center'}}>Humedad</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography sx={{fontSize: '12px', textAlign: 'center'}}>Modo</Typography>
                                        </Grid>
                                    </Grid>
                                </Line>
                                {steps ? steps.map((step, i)=>{
                                    return(<Line color='#434f55'>
                                        {Object.entries(step).length !== 0 ? (<Grid container>
                                            <Grid item xs={2}>{i+1}</Grid>
                                            <Grid item xs={4} sx={{textAlign: 'center'}}>
                                            {typeof step?.params?.Termometro === 'number' ? step?.params?.Termometro: 
                                            typeof step.params.Termometro === 'object' ? step?.params?.Termometro?.min: null}
                                            °C</Grid>
                                            <Grid item xs={4} sx={{textAlign: 'center'}}>{step?.params?.Humedad}%</Grid>
                                            <Grid item xs={2} sx={{textAlign: 'center'}}><IconMode mode={step?.params?.mode}/></Grid>
                                        </Grid>):
                                        (<Grid container>
                                            <Grid item xs={2}>{i+1}</Grid>
                                            <Grid item xs={8} sx={{textAlign: 'center'}}>Cargar</Grid>
                                            <Grid item xs={2} sx={{textAlign: 'center'}}><IconMode/></Grid>
                                        </Grid>)}
                                    </Line>)
                                }): null}
                            </Steps>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        ): null}
    </Box>);
}

const Steps = styled('ul')({ 
    width: '100%',
    paddingLeft: '0px !important'
});
const Line  = styled('li')(props => ({
    listStyle: 'none',
    color: 'txtColor' in props ? props.txtColor: 'black',
    border: 'color' in props ? `1.5px solid ${props.color}`: 'none',
    background: 'bg' in props ? props.bg: 'transparent',
    borderRadius: '30px !important',
    padding: '0px 15px',
    marginTop: '10px'
}))

const IconMode = ({mode}) =>{
    switch(mode){
        case 1: 
            return(<img width='10px' height='15px' src={convection}/>)
        case 2: 
            return(<img width='10px' height='15px' src={combi}/>)
        case 3: 
            return(<img width='10px' height='15px' src={steam}/>)
        default:
            return(<img width='17px' height='15px' src={oven}/>)
    }
}

export default SmartRecipes;