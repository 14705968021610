import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import '@fontsource/league-gothic';
import '@fontsource/lobster';
import '@fontsource/encode-sans-condensed';
import '@fontsource/material-icons';

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>,
  document.getElementById('root')
);
