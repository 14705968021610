import { Box, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

const Paretto = ({borderColor = 'gold', status = 0, children = false}) => {
    const [width, setWidth] = useState(0);
    const ref = useRef(null);

    useEffect(()=>{if(ref?.current?.clientWidth){setWidth(ref?.current?.clientWidth)}})

    useLayoutEffect(() => {
        const handleResize = () => setWidth(ref?.current?.clientWidth);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize) }
    }, []);

    return(<Box ref={ref} sx={{position: 'relative'}}>
        <Box sx={{
            width:`${width}px`,
            height:`${width}px`,
            background: `transparent`,
            borderRadius: '50%',
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'center',
            boxShadow: '0px 4px 13px -3px black'
        }}>
            <Box sx={{
                width:`${width}px`,
                height:`${width}px`,
                background: `conic-gradient(transparent 0deg, transparent 0deg 135deg, #373737 135deg 225deg, transparent 198deg)`,
                borderRadius: '50%',
                position: 'absolute',
                zIndex: 1
            }}/>
            <Box sx={{
                width:`${width}px`,
                height:`${width}px`,
                background: `conic-gradient(${borderColor} 0deg, ${borderColor} 0deg ${(status*275)/100}deg, transparent 0deg 200deg, transparent 200deg)`,
                borderRadius: '50%',
                rotate: '220deg',
                position: 'absolute',
                zIndex: 1
            }}/>
            <Box sx={{
                width:`${width}px`,
                height:`${width}px`,
                background: `linear-gradient(175deg, #939393, #e5e5e5)`,
                borderRadius: '50%',
                position: 'absolute',
                zIndex: 0
            }}/>
            <Box sx={{
                width:`${width-(width*0.18)}px`,
                height:`${width-(width*0.18)}px`,
                background: 'black',
                borderRadius: '50%',
                display: 'grid',
                justifyContent: 'center',
                alignContent:'center',
                zIndex: 4
            }}>
                {children ? children:(<Typography variant='h5' sx={{
                    color:'white',
                    fontWeight: 'bold',
                    fontSize: `${width*0.18}px`
                }}>{status}%</Typography>)}
            </Box>
        </Box>
    </Box>)
}

export default Paretto;