import React, {useReducer} from 'react'
import ingredientReducer from './ingredientReducer'
import IngredientContext from './ingredientContext'
import clientAxios  from '../../config/axios'

import {
    MOSTRAR_INGREDIENTES
} from '../../types'

const IngredientState = props => {
    const initialState = {
        ingredientes: []
    }

    const [ state, dispatch ] = useReducer(ingredientReducer, initialState)

    const obtenerIngredientes = async () => {
        try{
            const result = await clientAxios.get('/api/group/all')
            dispatch({
                type: MOSTRAR_INGREDIENTES,
                payload: result.data
            })

        } catch(err){console.log(err)}
    }

    return(
        <IngredientContext.Provider
            value={{
                ingredientes: state.ingredientes,
                obtenerIngredientes
            }}
        >{props.children}
        </IngredientContext.Provider>
    )
}

export default IngredientState;