import { Typography } from '@mui/material';
import React, {useContext, useEffect, useState} from 'react'
import ModuleContext from '../../context/modules/moduleContext';
import { MenuComponent as Menu } from '../material';
import UsersForm from './Users';
import Permissions from './Permissions';
import Groups from './Groups';

const Users = () => {
    const moduleContext = useContext(ModuleContext);
    const { moduloSeleccionado, modulos } = moduleContext;
    const [modulo, setModulo] = useState(false)

    useEffect(()=>{
        if(moduloSeleccionado){ setModulo(modulos.filter(m => m._id === moduloSeleccionado)) }
    },[moduloSeleccionado])

    return(<Menu route='Gestión de usuarios'>
        {modulo ? 
            modulo[0].route === "/users/permissions" ? (<Permissions/>): 
            modulo[0].route === "/users/groups" ? (<Groups/>): 
            modulo[0].route === "/" ? (<UsersForm/>): 
            <Permissions/> : <Permissions/>
        }
    </Menu>)
}

const Dashboard = () => {
    return(<Typography>Dashboard</Typography>)
}

export default Users;