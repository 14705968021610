import React, {useReducer} from 'react'
import recipeReducer from './recipeReducer'
import RecipeContext from './recipeContext'
import clientAxios  from '../../config/axios'

import {
    MOSTRAR_RECETAS,
    GUARDAR_RECETA,
    ELIMINAR_RECETA,
    MODIFICAR_RECETA
} from '../../types'

const RecipeState = props => {
    const initialState = { recetas: null };
    const [ state, dispatch ] = useReducer(recipeReducer, initialState);

    const obtenerRecetas = async () => {
        try{
            const result = await clientAxios.get('/api/recipe')
            dispatch({
                type: MOSTRAR_RECETAS,
                payload: result.data.data
            })
        }catch(err){ console.log(err) }
    }

    const guardarReceta = async (receta = false) => {
        try{
            if(receta){
                const result = await clientAxios.post('/api/recipe', receta);
                let nuevaReceta = await result.data.data;
                dispatch({
                    type: GUARDAR_RECETA,
                    payload: nuevaReceta
                })
            }else{
                console.log("Empty")
            }
        }catch(err){
            console.log(err)
        }
    }

    const modificarReceta = async (params = false) => {
        try{
            if(params){
                const result = await clientAxios.put('/api/recipe', params);
                let recetaEditada = await result.data.data;
                dispatch({
                    type: MODIFICAR_RECETA,
                    payload: recetaEditada
                })
            }else{
                console.log("Empty")
            }
        }catch(err){
            console.log(err)
        }
    }

    const eliminarReceta = async _id => {
        try{
            const result = await clientAxios.delete(`/api/recipe/${await _id}`)
            console.log(result)
            dispatch({
                type: ELIMINAR_RECETA,
                payload: _id
            })
        }catch(err){ console.log(err) }
    }

    return(
        <RecipeContext.Provider
            value={{
                recetas: state.recetas,
                obtenerRecetas,
                guardarReceta,
                eliminarReceta,
                modificarReceta
            }}
        >
            {props.children}
        </RecipeContext.Provider>
    )
}

export default RecipeState;
