import { Box, Fab, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import React, {useState, useEffect} from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Icons } from "../material";

const titles = {
    textAlign: 'center', 
    fontWeight: 'bold',
    fontFamily: 'Monserrat ProServicios',
    paddingY: '12px !important',
    fontSize: '18px'
}

const Permissions = () => {
    const [select, setSelect] = useState({
        miembro: 0,
        rol: 0,
        permiso: 0
    })
    const [miembros, setMiembros] = useState([
        {name: "Milton Ramos"},
        {name: "Marco Elizondo"},
        {name: "Perla Ibarra"},
        {name: "Humberto"},
    ])
    const [roles, setRoles] = useState([
        {name: 'Administrador'},
        {name: 'Lider'},
        {name: 'Ayudante de cocina'},
        {name: 'Personal'},
    ])
    const [permisos, setPermisos] = useState([
        {name: 'Recetas'},
        {name: 'Usuarios'},
        {name: 'Equipos'}
    ])

    const styleBox = {
        boxShadow: '0px 3px 7px -4px gray',
        minHeight: '50vh',
        background: 'white',
        borderRadius: '15px',
        height: '80vh'
    }
    
    return(<Box>
        <Grid container sx={{height: '76vh'}} spacing={2}>
            <Grid item xs={3}>
                <Box sx={styleBox}>
                    <Typography sx={titles}>Miembros</Typography>
                    <List component="nav" aria-label="secondary mailbox folder" sx={{'& .Mui-selected':{background: 'gold !important'}}}>
                        {miembros.length > 0 ? miembros.map((miembro, i)=> (<ListItemButton
                                selected={select.miembro === i}
                                sx={{'&:hover':{background: '#fff7ce !important'}}}
                                onClick={()=>setSelect({...select, miembro: i})}>
                            <ListItemText sx={{fontFamily: 'Monserrat ProServicios'}} primary={miembro.name} />
                        </ListItemButton>)):null}
                    </List>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box sx={styleBox}>
                    <Typography sx={titles}>Roles</Typography>
                    <List component="nav" aria-label="secondary mailbox folder" sx={{'& .Mui-selected':{background: 'gold !important'}}}>
                        {roles.length > 0 ? roles.map((rol, i)=> (<ListItemButton
                                selected={select.rol === i}
                                sx={{
                                    fontFamily: 'Monserrat ProServicios',
                                    '&:hover':{background: '#fff7ce !important'
                                }}}
                                onClick={()=>setSelect({...select, rol: i})}>
                            <ListItemText sx={{fontFamily: 'Monserrat ProServicios'}} primary={rol.name} />
                        </ListItemButton>)):null}
                    </List>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={styleBox}>
                    <Typography sx={titles}>Permisos</Typography>
                    <Grid container>
                        <Grid item></Grid>
                    </Grid>
                    {permisos.length > 0 ? permisos.map((permiso, i) => (<Box><IndeterminateCheckbox name={permiso.name}/></Box>)):null}
                </Box>
            </Grid>
        </Grid>
        <Fab
            variant="extended"
            size="medium"
            sx={{
                position: 'absolute',
                bottom: '0px',
                marginBottom: '32px',
                right: '32px',
                color: 'white',
                textTransform: 'none',
                background:'#445056',
                '&:hover':{ background:'#d8b915'}
            }}
            aria-label="add"
            // onClick={()=>callback(params)}
        >Guardar<Box sx={{marginLeft: '6px'}}><Icons name='Save'/></Box></Fab>
    </Box>)
}

const IndeterminateCheckbox = ({name = "Default"}) => {
    const colorCheck = {"&.Mui-checked": {color: '#475158'}}
    const [checked, setChecked] = useState([true, false, false, false]);

    const handleChange1 = (event) => { setChecked([event.target.checked, event.target.checked, event.target.checked, event.target.checked]) };
    const handleChange2 = (event) => { setChecked([event.target.checked, checked[1], checked[2], checked[3]]) };
    const handleChange3 = (event) => { setChecked([checked[0], event.target.checked, checked[2], checked[3]]) };
    const handleChange4 = (event) => { setChecked([checked[0], checked[1], event.target.checked, checked[3]]) };
    const handleChange5 = (event) => { setChecked([checked[0], checked[1], checked[2], event.target.checked]) };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        <FormControlLabel
            label="Crear"
            control={<Checkbox sx={colorCheck} checked={checked[0]} onChange={handleChange2} />}
        />
        <FormControlLabel
            label="Editar"
            control={<Checkbox sx={colorCheck} checked={checked[1]} onChange={handleChange3} />}
        />
        <FormControlLabel
            label="Leer"
            control={<Checkbox sx={colorCheck} checked={checked[2]} onChange={handleChange4} />}
        />
        <FormControlLabel
            label="Eliminar"
            control={<Checkbox sx={colorCheck} checked={checked[3]} onChange={handleChange5} />}
        />
    </Box>
  );

    return (<SimpleAccordion title={
        <FormControlLabel
            className="all-checked"
            label={name}
            control={<Checkbox
                color='default'
                checked={checked[0] && checked[1] && checked[2] && checked[3]}
                indeterminate={checked[0] !== checked[1] || checked[0] !== checked[2] || checked[0] !== checked[3]}
                onChange={handleChange1}
            />}
        />
    }>
        {children}
    </SimpleAccordion>);
}

const SimpleAccordion = ({children='Default', title='Titulo'}) => {
  return (
    <div>
      <Accordion sx={{ boxShadow: 'none !important' }}>
        <AccordionSummary
            sx={{
                minHeight: '40px !important',
                '& .MuiAccordionSummary-content':{
                    margin: '0px'
                }
            }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{padding: '0px 16px'}}>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Permissions;
