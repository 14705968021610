import React, {useReducer} from 'react'
import folderReducer from './folderReducer'
import FolderContext from './folderContext'
import clientAxios  from '../../config/axios'

import {
    CREAR_FOLDER,
    OBTENER_FOLDERS
} from '../../types'

const FolderState = props => {
    const initialState = { folders: [] };
    const [ state, dispatch ] = useReducer(folderReducer, initialState);

    const crearFolder = async params => {
        try{                
            const result = await clientAxios.post('/api/folder', params)
            dispatch({
                type: CREAR_FOLDER,
                payload: result.data
            })
        }catch(err){ console.log(err) }
    }

    const obtenerFolders = async () => {
        try{                
            const result = await clientAxios.get('/api/folder')
            dispatch({
                type: OBTENER_FOLDERS,
                payload: result.data.data
            })
        }catch(err){ console.log(err) }
    }

    return(
        <FolderContext.Provider
            value={{
                folders: state.folders,
                folder: state.folder,
                crearFolder,
                obtenerFolders
            }}
        >
            {props.children}
        </FolderContext.Provider>
    )
}

export default FolderState;
