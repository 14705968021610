import { GUARDAR_RECETA, MOSTRAR_RECETAS, ELIMINAR_RECETA } from '../../types'

export default( state, action) => {
    switch(action.type){
        case MOSTRAR_RECETAS:
            return { recetas: action.payload };
        case GUARDAR_RECETA:
            return {
                recetas: [
                    action.payload,
                    ...state.recetas
                ]
            }
        case ELIMINAR_RECETA:
            let recetas = state.recetas
            return {
                recetas: recetas.filter(recetaEliminada => recetaEliminada._id !== action.payload)
            }
        default:
            return state;
    }
}
